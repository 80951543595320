import React from "react";
import "./footer.css";
import { FaFacebook, FaLinkedin, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom"; // Import social media icons

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-section footer-logo">
                <h2>Velle</h2>
                <p>Empowering teachers to work smarter.</p>
                  <Link to="/auth/registration" className="footer-button">
                    Try for free
                  </Link>
            </div>
            <div className="footer-section">
                <h3>PRODUCT</h3>
                <ul>
                    <li>Assessment Creation Tool</li>
                    <li>AI Tools</li>
                    <li>Marking</li>
                    <li>Metrics and Stats</li>
                </ul>
            </div>
            <div className="footer-section">
                <h3>BY ROLE</h3>
                <ul>
                <li>Teacher</li>
                    <li>Schools</li>
                    <li>Districts</li>
                    <li>Assessments Coordinators</li>
                    <li>Home Schooling</li>
                </ul>
            </div>
            <div className="footer-section">
                <h3>RESOURCES</h3>
                <ul>
                    <li>Help Center</li>
                    <li>Book a Free Training</li>
                    <li>Blog</li>
                </ul>
            </div>
            <div className="footer-section footer-social">
                <h3>COMMUNITY</h3>
                <ul>
                    <li>Run Your Own Training</li>
                    <li>Become an Velle Hero</li>
                </ul>
                <div className="social-icons">
                    <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                        <FaFacebook />
                    </a>
                    <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
                        <FaTwitter />
                    </a>
                    <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                        <FaLinkedin />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
