import React from "react";

const AIChat = ({ onlineTestId }) => {
    const userInfo = JSON.parse(localStorage.getItem("user") || "{}");
    const token = userInfo.token || "";
    const email = userInfo.email || "";

    // Construct the URL with query parameters, including onlineTestId
    const streamlitUrl = `https://edu-assessments-rkadh8wfymdfe2uiwwahef.streamlit.app?token=${encodeURIComponent(token)}&email=${encodeURIComponent(email)}&online_test_id=${encodeURIComponent(onlineTestId)}&embed=true`;

    return (
        <div
            style={{
                padding: 0,
                margin: 0,
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
            }}
        >
            <iframe
                src={streamlitUrl}
                width="100%"
                height="100%"
                style={{
                    border: "none",
                    padding: 0,
                    margin: 0,
                    display: "block",
                    minHeight: "90vh",
                }}
                title="AI-Powered Marking Tool"
            ></iframe>
        </div>
    );
};

export default AIChat;
