import getConfig from "../../config/index";

const config = getConfig();

export const OnlineTestApiService = {
    async deleteOnlineTestByLearnerIdAndAssessmentId(learnerId, assessmentId, token) {
        try {
            const response = await fetch(`${config.api.base_url}/online-tests/clear_learner_uploaded_images_and_test/?learner_Id=${learnerId}&assessment_Id=${assessmentId}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.error || "Failed to remove test");
            }

            return response;  // Return the raw response object to handle in the frontend
        } catch (error) {
            console.error("Error removing test:", error);
            throw error;
        }
    },

    async submitLearnerScripts(learnerId, assessmentId, answerUrls, onlineTestID, token) {
        try {
            const url = `${config.api.base_url}/online-tests/create-test-and-submit-answer/`;

            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    learner_id: learnerId,
                    assessment_id: assessmentId,
                    answer_urls: answerUrls,
                    online_test_id: onlineTestID
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error("Failed to create test and submit answer:", response.status);
                return null;
            }
        } catch (error) {
            console.error("Error submitting test answers:", error);
        }
    },

    async fetchOnlineTestsByExamId(examId, courseId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/online-tests/fetch-by-exam-course/?exam_id=${examId}&course_id=${courseId}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error("Failed to fetch online tests for exam id", examId, ":", response.status);
                return null;
            }
        } catch (error) {
            console.error("Error fetching online tests:", error);
        }
    },

    async fetchImageUrlsByOnlineTestId(online_test_id, exam_id, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/online-tests/fetch_online_answer_urls/?online_test_id=${online_test_id}&exam_id=${exam_id}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error("Failed to fetch online test answer urls for exam id", online_test_id, ":", response.status);
                return null;
            }
        } catch (error) {
            console.error("Error fetching online tests answer urls:", error);
        }
    },

    async createOnlineTest(learnerId, assessmentId, token) {
        try {
            const url = `${config.api.base_url}/online-tests/create-online-test/`;

            const response = await fetch(url, {
                method: "POST",
                body: JSON.stringify({
                    learner_id: learnerId,
                    assessment_id: assessmentId
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error("Failed to create online test:", response.status);
                return null;
            }
        } catch (error) {
            console.error("Error creating online test:", error);
        }
    },

    // New method to fetch courses by exam (subject, grade, and teacher)
    async fetchCoursesByExam(examPaperId, token) {
        try {
            const url = `${config.api.base_url}/courses/courses-by-exam/?exam_paper_id=${examPaperId}`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
            });

            if (response.ok) {
                return await response.json();
            } else {
                console.error("Failed to fetch courses by exam paper id:", response.status);
                return null;
            }
        } catch (error) {
            console.error("Error fetching courses by exam paper id:", error);
        }
    },

     // New method to update comments and recommendations for an online test
    async updateCommentsAndRecommendations(onlineTestId, comment, recommendations, token) {
        try {
            const url = `${config.api.base_url}/online-tests/${onlineTestId}/update-comments-recommendations/`;

            const response = await fetch(url, {
                method: "PATCH",
                body: JSON.stringify({
                    comment,
                    recommendations
                }),
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
            });

            if (response.ok) {
                return await response.json(); // Return updated comment and recommendations
            } else {
                const errorData = await response.json();
                console.error("Failed to update comments and recommendations:", errorData);
                return null;
            }
        } catch (error) {
            console.error("Error updating comments and recommendations:", error);
        }
    },

    // New method to fetch learner results
    async fetchLearnerResults(onlineTestId, token) {
        try {
            const url = `${config.api.base_url}/online-tests/${onlineTestId}/fetch-learner-results/`;
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Token ${token}`,
                },
            });

            if (response.ok) {
                return await response.json(); // Return the learner results
            } else {
                const errorData = await response.json();
                console.error("Failed to fetch learner results:", errorData);
                return null;
            }
        } catch (error) {
            console.error("Error fetching learner results:", error);
        }
    },


};