import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, IconButton, Typography, Button, Container } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const AssessmentHeader = ({ name, onDownloadWithAnswers, onDownload }) => {
    const navigate = useNavigate();

    const handleHomeClick = () => {
        navigate("/home");
    };

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: "#ffffff",
                borderBottom: "1px solid #d3d3d3",
                boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
                paddingY: 1.5,
                position: 'fixed',
                top: 0,
                zIndex:1000
            }}
        >
            <Container maxWidth="xl" sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                <Box display="flex" alignItems="center">
                    <IconButton onClick={handleHomeClick} aria-label="home" sx={{ color: "#6c757d" }}>
                        <HomeIcon fontSize="small" />
                    </IconButton>
                    <ChevronRightIcon fontSize="small" sx={{ color: "#6c757d" }} />
                    <Typography variant="subtitle1" component="span" sx={{ fontWeight: 500 }}>
                        {name}
                    </Typography>
                </Box>

                <Box display="flex" alignItems="center">
                    <Button
                        variant="contained"
                        onClick={onDownloadWithAnswers}
                        startIcon={<FileDownloadOutlinedIcon />}
                        sx={{
                            backgroundColor: "#00b8d4",
                            color: "#fff",
                            textTransform: "none",
                            borderRadius: 10,
                            fontSize: "0.875rem",
                            fontWeight: 500,
                            mr: 1,
                            paddingX: 2,
                            "&:hover": { backgroundColor: "#00a0ba" },
                            boxShadow: "0px 4px 6px rgba(0, 184, 212, 0.2)",
                        }}
                    >
                        Download with answers
                    </Button>
                    <Button
                        variant="contained"
                        onClick={onDownload}
                        startIcon={<FileDownloadOutlinedIcon />}
                        sx={{
                            backgroundColor: "#00b8d4",
                            color: "#fff",
                            textTransform: "none",
                            borderRadius: 10,
                            fontSize: "0.875rem",
                            fontWeight: 500,
                            paddingX: 2,
                            "&:hover": { backgroundColor: "#00a0ba" },
                            boxShadow: "0px 4px 6px rgba(0, 184, 212, 0.2)",
                        }}
                    >
                        Download
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default AssessmentHeader;