import React from "react";
import { Box, IconButton, Modal, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const FeedbackChat = ({ open, handleClose, cAId, type, questionId }) => {
    const userInfo = JSON.parse(localStorage.getItem("user") || "{}");
    const token = userInfo.token || "";
    const email = userInfo.email || "";

    // Construct the URL with query parameters, including cAId, type, and questionId
    const streamlitUrl = `https://educase.streamlit.app?token=${encodeURIComponent(token)}&email=${encodeURIComponent(email)}&cAId=${encodeURIComponent(cAId)}&type=${encodeURIComponent(type)}&question_id=${encodeURIComponent(questionId)}&embed=true`;

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="feedback-chat-modal">
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "80%",
                    height: "90vh",
                    bgcolor: "background.paper",
                    boxShadow: 24,
                    p: 2,
                    borderRadius: 2,
                }}
            >
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">AI Feedback Chat</Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <iframe
                    src={streamlitUrl}
                    width="100%"
                    height="90%"
                    style={{ border: "none", marginTop: "10px" }}
                    title="AI Feedback Chat"
                    sandbox="allow-scripts allow-same-origin allow-forms"
                ></iframe>
            </Box>
        </Modal>
    );
};

export default FeedbackChat;