import React from "react";
import { Box, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

const BreadcrumbNavigation = ({ assessmentName, grade, subject, learnerName, onBackToSummaryClick }) => {
  return (
    <Box
     sx={{
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        borderRadius: '24px',
        backgroundColor: 'white',
        boxShadow: 'none',
        marginBottom: { xs: 0, sm: 2 },
        marginTop: { xs: 0, sm: 2 },
        width: 'fit-content',
      }}
    >
      {/* Assessment Name, Grade, and Subject */}
      <Typography
        variant="body1"
        sx={{
          fontWeight: learnerName ? 'normal' : 'bold',
          color: 'black',
          cursor: 'pointer',
          fontSize: 'clamp(0.875rem, 1vw + 0.5rem, 1.25rem)',
        }}
        onClick={onBackToSummaryClick}
      >
        {assessmentName} - Grade {grade} ({subject}) Performance Results
      </Typography>

      {/* Learner's Results (if applicable) */}
      {learnerName && (
        <>
          <ChevronRightIcon
            sx={{
              mx: 1,
              color: (theme) => theme.palette.text.secondary,
              fontSize: 'clamp(0.875rem, 1.5vw + 0.5rem, 1.5rem)',
            }}
          />
          <Typography
            variant="body1"
            sx={{
              color: 'black',
              fontWeight: 'bold',
              fontSize: 'clamp(0.875rem, 1vw + 0.5rem, 1.25rem)',
            }}
          >
            {learnerName}'s Results
          </Typography>
        </>
      )}
    </Box>
  );
};

export default BreadcrumbNavigation;
