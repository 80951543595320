import {DataGrid} from "@mui/x-data-grid";
import IconButton from "@mui/material/IconButton";
import React, {useState} from "react";
import {format, formatDistanceToNow, isBefore, subWeeks} from "date-fns"; // Import necessary functions
import Button from "@mui/material/Button"; // Import Button component for "New Assessment"
import "./assessmentTable.css";
import {useDispatch} from "react-redux";
import {populateState} from "../../reducers/assessment";
import {addSelectedTopic} from "../../reducers/selectedTopics";
import {useNavigate} from "react-router-dom";
import TextField from "@mui/material/TextField";
import Swal from "sweetalert2";
import AssessmentAPIService from "../../services/api/AssessmentAPIService";
import DeleteIcon from "@mui/icons-material/Delete";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CopyTestLinkDialog from "../CopyTestLinkDialog/CopyTestLinkDialog";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const AssessmentTable = ({assessments, setAssessments}) => {
    const [searchText, setSearchText] = useState("");
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const [openModal, setOpenModal] = useState(false);
    const [selectedAssessmentId, setSelectedAssessmentId] = useState(null);
    const {token} = parsedUserInfo;
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const fetchFullExamData = async (assessment_id) => {
        try {
            // Call the actual service to fetch assessment data
            const assessment = await AssessmentAPIService.fetchAssessment(assessment_id, token);

            if (assessment) {
                // Construct assessmentDetails with the required structure
                const assessmentDetails = {
                    examId: assessment.id,
                    name: assessment.name,
                    subjectName: assessment.subject.name,
                    subjectId: assessment.subject.id,
                    grade: assessment.grade,
                    type: assessment.type,
                    date: assessment.assessment_date,
                    questions: assessment.questions || [],
                    instructions: assessment.instructions
                };

                localStorage.removeItem("assessmentDetails");

                localStorage.setItem("assessmentDetails", JSON.stringify(assessmentDetails));

                // Navigate to the assessment details page with the constructed assessmentDetails state
                navigate("/create-assessment", {state: {assessmentDetails}});
            } else {
                console.error("Failed to fetch full assessment data.");
            }
        } catch (error) {
            console.error("Error fetching full exam data:", error);
        }
    };


    const handleEdit = async (paperId) => {
        try {
            const assessment = await fetchFullExamData(paperId);

            if (assessment) {
                dispatch(populateState(assessment));
                await dispatch(addSelectedTopic(assessment.topics || []));
                navigate("/assessment-details");
            } else {
                console.error("Failed to fetch full assessment data.");
            }
        } catch (error) {
            console.error("Error during handleEdit:", error);
        }
    };

    const handleCopy = (id) => {
        setSelectedAssessmentId(id);
        setOpenModal(true);
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleRowDoubleClick = (params) => {
        handleEdit(params.row.id); // Call the edit functionality when row is double-clicked
    };

    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget); // Set the anchor element for the menu
        setSelectedRow(row); // Set the selected row for performing actions on it
    };

    const handleMenuClose = () => {
        setAnchorEl(null); // Close the menu
        setSelectedRow(null); // Clear selected row
    };

    const renderAssessmentDetails = (params) => {
        return (
            <div style={{flex: 1}}>
                <div style={{fontWeight: "bold", fontSize: "1rem", color: "#5b7f90"}}>{params.row.name}</div>
                <div style={{color: "#5b7f90", paddingTop: "5px"}}>{params.row.subject.name}</div>
                <div style={{color: "#5b7f90", paddingTop: "2px"}}>Grade {params.row.grade}</div>
            </div>
        );
    };

    const renderLastModifiedWithMenu = (params) => {
        const lastModifiedDate = new Date(params.value);
        const threeWeeksAgo = subWeeks(new Date(), 3);

        // Show time-lapsed format if within 3 weeks, otherwise show the full date
        const timeDisplay = isBefore(lastModifiedDate, threeWeeksAgo)
            ? format(lastModifiedDate, "yyyy-MM-dd HH:mm:ss")
            : `${formatDistanceToNow(lastModifiedDate)} ago`;

        return (
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%"}}>
                <span style={{flex: 1}}>{timeDisplay}</span> {/* This ensures the time stays on the left */}
                <div style={{textAlign: "right", marginLeft: "auto"}}>
                    <IconButton onClick={(e) => handleMenuClick(e, params.row)}>
                        <MoreVertIcon/>
                    </IconButton>
                </div>
            </div>
        );
    };

    const columns = [
        {
            field: "name",
            headerName: "Assessment",
            flex: 3,
            align: "left",
            headerClassName: "centered-header",
            renderCell: renderAssessmentDetails,
        },
        {
            field: "last_updated",
            headerName: "Last Modified",
            align: "right",
            width: 180,
            headerClassName: "centered-header",
            renderCell: renderLastModifiedWithMenu,
        }
    ];

    const handleDelete = async (assessmentId) => {
        try {
            const result = await Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            });

            if (result.isConfirmed) {
                const response = await AssessmentAPIService.deleteAssessment(assessmentId, token);
                if (response.ok) {
                    Swal.fire(
                        'Deleted!',
                        'The assessment has been deleted.',
                        'success'
                    );

                    setAssessments(prevAssessments => prevAssessments.filter(assessment => assessment.id !== assessmentId));
                } else {
                    const res = await response.json();
                    console.error("Error deleting assessment:", res.error);
                    Swal.fire(
                        'Error!',
                        'There was an error deleting the assessment. Please try again.',
                        'error'
                    );
                }
            }
        } catch (error) {
            console.error("Error deleting assessment:", error);
            await Swal.fire(
                'Error!',
                'An unexpected error occurred. Please try again later.',
                'error'
            );
        }
    };

    // Filter assessments based on search text
    const filteredAssessments = assessments.filter(
        (assessment) =>
            assessment.id.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.grade.toString().toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.subject.name.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.type.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.name.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            {/* Flex container for "New Assessment" and Search */}
            <div style={{display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "20px"}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => navigate("/assessment-details")}  // Assuming this is your new assessment route
                    style={{textTransform: "none", padding: "5px 10px", borderRadius: 25}}
                >
                    New Assessment
                </Button>
                <TextField
                    id="search"
                    placeholder="Search"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        style: {width: "100%", borderRadius: 25},
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                            borderColor: "#d0d4d9",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#d0d4d9",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#b3b3b3",
                        },
                        "& .MuiInputBase-input": {
                            padding: "10px",
                            color: "#5f9ea0",
                        },
                    }}
                />
            </div>

            <div style={{height: 400, width: "100%"}}>
                <DataGrid
                    rows={filteredAssessments}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                    disableSelectionOnClick
                    autoHeight
                    rowHeight={80}
                    onRowDoubleClick={handleRowDoubleClick}
                    sx={{
                        backgroundColor: "white",
                        borderRadius: '10px',
                        boxShadow: 0,
                        border: "1px solid #d0d4d9",  // Light border around the grid
                        [`& .${"MuiDataGrid-cell"}:focus-within`]: {
                            outline: "none",
                        },
                        "& .MuiDataGrid-cell": {
                            borderBottom: "1px solid #d0d4d9",  // Add a light border between rows
                            padding: "20px",  // Keep padding for cell content
                            whiteSpace: "nowrap",  // Prevent text wrapping
                            overflow: "hidden",  // Hide overflowed text
                            textOverflow: "ellipsis",  // Show ellipsis for long text
                        },
                        "& .MuiDataGrid-columnHeaders": {
                            backgroundColor: "#f3f7f8",  // Set the desired background color for column headers
                            borderBottom: "1px solid #d0d4d9",  // Add bottom border for column headers
                        },
                        "& .MuiDataGrid-columnHeaderTitle": {
                            color: "#555f67",  // Set text color to match the style you requested
                            fontWeight: "bold",  // Make the header text bold
                        },
                        "& .MuiDataGrid-columnSeparator--sideRight, & .MuiDataGrid-iconButtonContainer": {
                            display: "none", // Hides the column menu button and vertical lines
                        },
                        "& .MuiDataGrid-sortIcon": {
                            display: "none", // Hides the sort icon
                        },
                        // Add hover effect for rows
                        "& .MuiDataGrid-row:hover": {
                            backgroundColor: "#dce7ea",  // Set a darker background color on hover
                        },
                    }}
                />


                {/* The Menu component triggered by the menu icon */}
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    PaperProps={{
                        style: {
                            border: '1px solid #87b6b7',
                            boxShadow: 'none',
                        },
                    }}
                >
                    <MenuItem onClick={() => handleEdit(selectedRow?.id)}>
                        <ModeEditOutlinedIcon fontSize="small" style={{marginRight: 8}}/>
                        Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleCopy(selectedRow?.id)}>
                        <ContentCopyIcon fontSize="small" style={{marginRight: 8}}/>
                        Copy
                    </MenuItem>
                    <MenuItem onClick={() => handleDelete(selectedRow?.id)}>
                        <DeleteIcon fontSize="small" style={{marginRight: 8}}/>
                        Delete
                    </MenuItem>
                </Menu>
            </div>

            <CopyTestLinkDialog
                open={openModal}
                onClose={handleClose}
                selectedAssessmentId={selectedAssessmentId}
                parsedUserInfo={parsedUserInfo}
            />
        </>
    );
};

export default AssessmentTable;
