import axios from "axios";
import Swal from "sweetalert2";
import UploadPdfToCloudflare from "../utils/UploadPdfToCloudflare";

export const generatePDF = async (htmlString, name) => {
    // Initialize a persistent toast notification
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 0, // No auto-close
        onBeforeOpen: () => {
            Swal.showLoading(); // Show a loader inside the toast
        }
    });

    Toast.fire({
        icon: "info",
        title: `Preparing ${name}.pdf for download...`,
    });

    const cssString = `
.exam-cover {
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #000;
  padding: 0 10mm;
  height: 70git mm;
  display: grid; /* Change display to grid */
}

.exam-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  grid-template-columns: 1fr 1fr; /* Divide into two columns */
}


.exam-details {
  text-align: left;
  margin-right:60mm;
}

.exam-logo-container {
  display: flex;
  /* Center the logo horizontally */
  align-items: right;

  /* Center the logo vertically */
}

.exam-logo {
  display: flex;
  padding-top:38px;
  align-items: center; /* Vertically center the content */
  justify-content: flex-start; /* Align content to the left */
  height: 50mm;
  /* Ensure the container height is 45mm */
}

@media print {
  .question {
    page-break-inside: avoid;
    break-inside: avoid;
  }
}


.detail-item {
  display: flex;
  align-items: center;
  font-size: 12pt;
  margin: 5mm 0;
  font-size: 18px;
  font-family: Georgia, 'Times New Roman', Times, serif;
}

strong {
  margin-right: 2mm; 
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
}
.katex .katex-html {
    display: none;
}

.write-in {
  flex-grow: 1;
  /* Allow line to fill space in detail item */
  border-bottom: 1px solid black;
  /* Line for writing */
  margin-left: 5mm;
  /* Space between label and line */
}

// Question
@media print {
    .question {
        page-break-inside: avoid;
        break-inside: avoid;
    }
}

.question-headline {
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
}

.question-marks {
    text-align: right;
    width: 100%;
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
}

.instructions {
    margin-left: 0;
    margin-bottom: 10px;
    margin-top: 0;
}

.total-marks {
    text-align: right;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    font-family: 'Times New Roman', Times, serif;
}

.tag-display {
    display: flex;
    justify-content: flex-start;
    color: #5F9EA0;
    /* Your main theme color */
    margin-top: 15px;
}

.question-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.icon {
    font-size: 32px;
}


.sub-question {
    flex: 1;
}

/* Tablet Styles (320px - 640px) */
@media (max-width: 640px) and (min-width: 320px) {
    .question-headline {
        font-size: 16px;
    }

    .question-marks {
        font-size: 16px;
    }

    .total-marks {
        font-size: 16px;
    }
}

/* Mobile Styles (0px - 320px) */
@media (max-width: 320px) {
    .question-headline {
        font-size: 11px;
    }

    .question-marks {
        font-size: 11px;
    }

    .total-marks {
        font-size: 11px;
    }
}

/* Sub-Question */
.sub-question {
    cursor: text;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: 5px;
    margin-top: 0;
}

.sub-question-item {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.sub-question-content {
    display: flex;
    align-items: baseline;
    width: 100%;
}


.sub-question-number {
    margin-right: 5px;
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
}

.sub-question-marks {
    margin-left: auto;
    font-size: 18px;
}

.answer-container {
    padding: 10px;
    margin: 10px 0;
}

.answer-label {
    font-weight: bold;
    margin-bottom: 8px;
}

@media (max-width: 640px) and (min-width: 321px) {
    .sub-question-marks,
    .sub-question-number {
        margin-right: 5px;
        font-size: 16px;
        font-family: 'Times New Roman', Times, serif;
    }
}

@media (max-width: 320px) {
    .sub-question-marks,
    .sub-question-number {
        margin-right: 5px;
        font-size: 11px;
        font-family: 'Times New Roman', Times, serif;
    }
}

/*Nested Sub-question*/
.answer-container {
    padding: 10px;
    margin: 10px 0;
  }
  
.answer-label {
font-weight: bold;
margin-bottom: 8px;
}

.list-container {
    padding-left: 30px;
    margin-left: 0;
    margin-bottom: 5px;
    margin-top: 0px;
}

.item-container {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}
.nested-question-number {
    margin-right: 5px;
    font-size: 18px;
    font-family: 'Times New Roman', Times, serif;
}

.flex-start-detail {
    display: flex;
    justify-content: flex-start;
    color: #5F9EA0;  /* Your main theme color */
    font-size: 12px;
    margin-top: 5px;
}

.nested-question-marks {
    margin-left: auto;
    font-size: 18px;
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 640px) and (min-width: 321px) {
    .nested-question-marks,
    .nested-question-number {
        margin-right: 5px;
        font-size: 16px;
        font-family: 'Times New Roman', Times, serif;
    }
}

@media (max-width: 320px) {
    .nested-question-marks,
    .nested-question-number {
        margin-right: 5px;
        font-size: 11px;
        font-family: 'Times New Roman', Times, serif;
    }
}

/* DisplayContent.css */
.display-content-content p,
.display-content-content h1,
.display-content-content h2,
.display-content-content h3,
.display-content-content h4,
.display-content-content h5,
.display-content-content h6,
.display-content-content span,
.display-content-content li,
.display-content-content a,
.display-content-content div {
    margin: 10px;
    font-family: 'Times New Roman', Times, serif;
    font-size: 18px;
}

.display-content-content img {
    max-width: 80%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer; 
}

/* Tablet Styles (320px - 640px) */
@media (max-width: 640px) and (min-width: 320px) {
    .display-content-content p,
    .display-content-content h1,
    .display-content-content h2,
    .display-content-content h3,
    .display-content-content h4,
    .display-content-content h5,
    .display-content-content h6,
    .display-content-content span,
    .display-content-content li,
    .display-content-content a,
    .display-content-content div {
        font-size: 16px;
    }

    .display-content-content img {
        max-width: 80%;
    }
}

/* Mobile Styles (0px - 320px) */
@media (max-width: 320px) {
    .display-content-content p,
    .display-content-content h1,
    .display-content-content h2,
    .display-content-content h3,
    .display-content-content h4,
    .display-content-content h5,
    .display-content-content h6,
    .display-content-content span,
    .display-content-content li,
    .display-content-content a,
    .display-content-content div {
        font-size: 9px;
        max-width: fit-content;
    }

    .display-content-content img {
        max-width: 70%;
    }
}
`;

    const options = {
        method: "POST",
        url: "https://api.pdfendpoint.com/v1/convert",
        headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer pdfe_live_c02eb517b140f15dba59594d7a15675203f6",
        },
        responseType: 'blob', // This line is important
        data: JSON.stringify({
            html: htmlString,
            scroll_whole_page: true,
            no_images: false,
            no_links: true,
            no_backgrounds: false,
            no_javascript: false,
            no_blank_pages: true,
            no_css: false,
            no_ads: true,
            no_forms: true,
            sandbox: false,
            margin_top: "2cm",
            margin_bottom: "2cm",
            margin_right: "1cm",
            margin_left: "1cm",
            footer_text: "page {{pageNum}}",
            header_text: "page {{pageNum}}",
            header_align: 'center',
            footer_align: 'center',
            viewport: "1123x1400",
            header_start_at: 2,
            footer_start_at: 2,
            css: cssString,
            delivery_mode: "inline",

        }),
    };

    try {
        const response = await axios.request(options);
        const pdfBlob = new Blob([response.data], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(pdfBlob);

        const uploadedUrl = await UploadPdfToCloudflare(pdfBlob, null, null, null, "VELLE");

        // uploadedUrl save to database

        const a = document.createElement("a");
        a.href = url;
        a.download = name + '.pdf'; // Set the file name for the download
        document.body.appendChild(a); // Append to the document but keep it hidden
        a.click(); // Simulate click to trigger download
        document.body.removeChild(a); // Clean up and remove the element

        Swal.fire({
            icon: "success",
            title: `${name}.pdf downloaded successfully`,
            timer: 3000
        });

    } catch (error) {
        console.error(error);
        Swal.fire({
            icon: "error",
            title: `Failed to download ${name}.pdf`,
            timer: 3000
        });
    }
};
