import axios from "axios";

const UploadPdfToCloudflare = async (
    pdfBlob,
    url,
    learnerId = "learnerIdNull",
    assessmentId = "assessmentIdNull",
    organisationId = "organisationNameNull",
) => {
    try {

        // Prepare form data for uploading the PDF
        const formData = new FormData();
        const pdfFileName = `educase_learners_answer_${learnerId}_${assessmentId}.pdf`;
        formData.append("document", pdfBlob, pdfFileName);
        formData.append("userId", learnerId);
        formData.append("assessmentId", assessmentId);
        formData.append("organisationId", organisationId)

        // Upload the PDF to Cloudflare
        const response = await axios.post(
            `https://scripts.educasetools.workers.dev/upload-pdf`,
            formData,
            {
                headers: {
                    'X-Custom-Auth-Key': '94CA5CDACCEEED5A8BCA28C853137',
                    'Content-Type': 'multipart/form-data'
                }
            }
        );

        // Return the URL of the uploaded PDF
        return response.data.url;

    } catch (error) {
        console.error("Error generating or uploading PDF:", error);
        throw new Error("Failed to generate or upload PDF");
    }
};

export default UploadPdfToCloudflare;