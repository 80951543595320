import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useLocation, useNavigate } from "react-router-dom";
import CourseAssessmentResultsApiService from "../../../../services/api/CourseAssessmentResultsApiService";
import "./CourseAssessmentTable.css";

const CourseAssessmentTable = () => {
    const [assessments, setAssessments] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);
    const navigate = useNavigate();
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const markingType = queryParams.get("type") || "manual";  // Default to 'manual' if not provided

    // Determine the display text based on the marking type
    const markingTitle = markingType === "ai" ? "AI Marking" : "Manual Marking";
    const markingDescription = markingType === "ai"
        ? "This page shows all the assessments submitted for AI marking. The AI will automatically evaluate learner submissions."
        : "This page shows all the assessments submitted for manual marking. You can manually review and grade each submission.";

    // Fetch assessments when the component loads
    useEffect(() => {
        const fetchAssessments = async () => {
            try {
                const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
                const token = userInfo.token;

                const response = await CourseAssessmentResultsApiService.getAllCourseAssessmentResults(token);

                // Sort assessments by latest submission date
                const sortedAssessments = response.sort((a, b) => new Date(b.assessment_date) - new Date(a.assessment_date));

                setAssessments(sortedAssessments);
            } catch (error) {
                console.error("Error fetching course assessment results:", error);
            }
        };

        fetchAssessments();
    }, []);

    // Menu handling
    const handleMenuClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    // Handle row click
    const handleRowClick = (params) => {
        const clickedExamId = params.row.exam_paper;
        const clickedCourseId = params.row.course;
        const clickedCAId = params.row.id;
        navigate(`/marking/get-submitted-tests/${clickedExamId}/${clickedCourseId}/${clickedCAId}?type=${markingType}`);
    };

    // Utility function to get initials for Avatar
    const getInitials = (name) => {
        if (!name) return '';
        return name.slice(0, 2).toUpperCase();  // Get the first two letters of the name
    };

    // DataGrid column definitions
    const columns = [
        {
            field: "exam_name",
            headerName: "Assessment Name",
            flex: 2,
            align: "left",
            headerClassName: "centered-header",
            renderCell: (params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ width: 32, height: 32, marginRight: 1, backgroundColor: '#5f9fa1' }}>
                        {getInitials(params.value)}
                    </Avatar>
                    <span>{params.value}</span>
                </div>
            ),
        },
        {
            field: "course_name",
            headerName: "Course Name",
            flex: 1.5,
            align: "left",
            headerClassName: "centered-header",
        },
        {
            field: "total_submitted",
            headerName: "Submitted",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
        },
        {
            field: "total_learners",
            headerName: "Marked",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
            renderCell: (params) => (
                <span>{params.row.total_learners} / {params.row.total_submitted}</span>
            ),
        },
        {
            field: "status_display",
            headerName: "Status",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
        },
        {
            field: "actions",
            headerName: "",
            width: 40,
            align: "center",
            sortable: false,
            renderCell: (params) => (
                <>
                    <IconButton onClick={(e) => handleMenuClick(e, params.row)}>
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                    >
                        <MenuItem onClick={() => console.log("Edit", selectedRow?.id)}>
                            Edit
                        </MenuItem>
                        <MenuItem onClick={() => console.log("Delete", selectedRow?.id)}>
                            Delete
                        </MenuItem>
                    </Menu>
                </>
            ),
        },
    ];

    // Filter assessments based on search input
    const filteredAssessments = assessments.filter(
        (assessment) =>
            assessment.course_name.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.exam_name.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.assessment_date?.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.status.toLowerCase().includes(searchText.toLowerCase()),
    );

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "20px",
                flexDirection: "column",
                gap: "30px",
            }}>
                {/* Marking Type Title */}
                <h1 style={{ color: markingType === "ai" ? "#f6a00a" : "#5f9ea0" }}>
                    {markingTitle}
                </h1>
                <p>{markingDescription}</p>

                {/* Search Bar */}
                <TextField
                    id="search"
                    placeholder="Search"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        style: { width: "200px", borderRadius: 25 },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                            borderColor: "#d0d4d9",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#d0d4d9",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#b3b3b3",
                        },
                        "& .MuiInputBase-input": {
                            padding: "10px",
                            color: "#5f9ea0",
                        },
                    }}
                />

                {/* Data Grid for Assessments */}
                <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                        rows={filteredAssessments}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        autoHeight
                        onRowClick={handleRowClick}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            backgroundColor: "white",
                            borderRadius: '10px',
                            border: "none",
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default CourseAssessmentTable;
