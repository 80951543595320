import { Link } from "react-router-dom";
import React, { useState } from "react";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Collapse, IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import EducaseLogoLight from "../../assets/educase-logo-light2.png";
import "./navBar.css";
import HomeIconImage from "../../assets/home.png";
import ViewMetrics from "../../assets/report.png";
import ViewAssessments from "../../assets/viewassessments.png";
import { useDispatch, useSelector } from "react-redux";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { setMobileIsNavBarVisible } from "../../reducers/componentStates";
import AssignmentIcon from "@mui/icons-material/Assignment";

const NavBar = () => {
    const dispatch = useDispatch();
    const [activeItem, setActiveItem] = useState(null);
    const [isMarkingOpen, setIsMarkingOpen] = useState(false);
    const [isMobile] = useState(window.innerWidth <= 700);
    const isMobileNavBarVisible = useSelector((state) => state.componentStates.isMobileNavBarVisible);

    const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = userInfo.user_role || '';

    const handleMenuItemClick = (menuItem) => {
        dispatch(setMobileIsNavBarVisible(false));
        setActiveItem(menuItem);
    };

    const handleMarkingClick = () => {
        setIsMarkingOpen(!isMarkingOpen);
    };

    if (isMobile && !isMobileNavBarVisible) {
        return null;
    }

    return (
        <nav className={"nav-bar"} tabIndex={0}>
            <div className="nav-bar-header">
                <img src={EducaseLogoLight} alt="Company Logo" className="nav-logo" />
            </div>
            <MenuList className="menu-list">
                <div className={"close-navbar-home-button"}>
                    <Link to="/home" style={{ color: "#4a606e", textDecoration: "none", flex: 1 }}>
                        <MenuItem className={`menu-item ${activeItem === "/" ? "active-item" : ""}`}
                                  onClick={() => handleMenuItemClick("/")}>
                            <ListItemIcon>
                                <img src={HomeIconImage} alt="Home" className="icon"
                                     style={{ width: "20px", height: "20px" }} />
                            </ListItemIcon>
                            <ListItemText>Home</ListItemText>
                        </MenuItem>
                    </Link>
                    {isMobile && (
                        <IconButton className="close-nav-button"
                                    onClick={() => dispatch(setMobileIsNavBarVisible(false))}>
                            <span>X</span>
                        </IconButton>
                    )}
                </div>

                <Link to="/assessments" style={{ color: "#4a606e", textDecoration: "none" }}>
                    <MenuItem className={`menu-item ${activeItem === "/assessments" ? "active-item" : ""}`}
                              onClick={() => handleMenuItemClick("/assessments")}>
                        <ListItemIcon>
                            <img src={ViewAssessments} alt="ViewAllAssessments" className="icon"
                                 style={{ width: "20px", height: "20px" }} />
                        </ListItemIcon>
                        <ListItemText>Assessments</ListItemText>
                    </MenuItem>
                </Link>


            <Link to="/upload-scripts" style={{ color: "#4a606e", textDecoration: "none" }}>
                <MenuItem className={`menu-item ${activeItem === "/upload-scripts" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/upload-scripts")}>
                    <ListItemIcon>
                        <AssignmentTurnedInIcon style={{ color: '#4CAF50' }} /> {/* Marking icon */}
                    </ListItemIcon>
                    <ListItemText>Marking Submissions</ListItemText>
                </MenuItem>
            </Link>

                {!isMobile && (userRole === "admin" || userRole === "marker") && (
                    <>
                        <MenuItem className="menu-item" onClick={handleMarkingClick}>
                            <ListItemIcon>
                                <AssignmentTurnedInIcon style={{ color: "#4CAF50" }} />
                            </ListItemIcon>
                            <ListItemText>Marking</ListItemText>
                            {isMarkingOpen ? <ExpandLess /> : <ExpandMore />}
                        </MenuItem>

                        <Collapse in={isMarkingOpen} timeout="auto" unmountOnExit>
                            <MenuList component="div" disablePadding>
                                {/* Manual Marking Link */}
                                <Link
                                    to="/get-assessment-course?type=manual"
                                    style={{ color: "#4a606e", textDecoration: "none" }}
                                >
                                    <MenuItem
                                        className={`menu-item ${activeItem === "/get-assessment-course" ? "active-item" : ""}`}
                                        onClick={() => handleMenuItemClick("/get-assessment-course")}
                                        style={{ paddingLeft: 32 }}
                                    >
                                        <ListItemIcon>
                                            <AssignmentIcon style={{ color: "#f44336" }} />
                                        </ListItemIcon>
                                        <ListItemText>Manual Marking</ListItemText>
                                    </MenuItem>
                                </Link>

                                {/* AI Marking Link */}
                                <Link
                                    to="/get-assessment-course?type=ai"
                                    style={{ color: "#4a606e", textDecoration: "none" }}
                                >
                                    <MenuItem
                                        className={`menu-item ${activeItem === "/get-assessment-course" ? "active-item" : ""}`}
                                        onClick={() => handleMenuItemClick("/get-assessment-course")}
                                        style={{ paddingLeft: 32 }}
                                    >
                                        <ListItemIcon>
                                            <AutoAwesomeIcon style={{ color: "#f6a00a" }} />
                                        </ListItemIcon>
                                        <ListItemText>AI Marking</ListItemText>
                                    </MenuItem>
                                </Link>
                            </MenuList>
                        </Collapse>

                    </>
                )}

                <Link to="/course-results" style={{ color: "#4a606e", textDecoration: "none" }}>
                    <MenuItem className={`menu-item ${activeItem === "/course-results" ? "active-item" : ""}`}
                              onClick={() => handleMenuItemClick("/course-results")}>
                        <ListItemIcon>
                            <img src={ViewMetrics} alt="ViewMetrics" className="icon"
                                 style={{ width: "20px", height: "20px" }} />
                        </ListItemIcon>
                        <ListItemText>Results & Insights</ListItemText>
                    </MenuItem>
                </Link>
            </MenuList>

            <div className="nav-bar-footer"
                 style={{ padding: "10px", marginTop: "auto", textAlign: "center", color: "#4a606e" }}>
                <Typography variant="body2" component="div">
                    Copyright © 2024 Educase
                </Typography>
            </div>
        </nav>
    );
};

export default NavBar;
