import React, {useState} from "react";
import EditorComponent from "../../components/Common/Quill/quillEditor";
import TagOptionsForm from "./tagOptions";
import AddIcon from "@mui/icons-material/Add";
import NestedQuestionComponent from "./nestedQuestion";
import {Box, Grid, IconButton, Typography} from "@mui/material";


const GenericWithSubquestionsComponent = ({
                                              index,
                                              question,
                                              handleAddNestedSubquestion,
                                              handleAddSubquestion,
                                              handleNestedSubQuestionTagChange,
                                              handleSubquestionChange,
                                              handleSubQuestionTagChange,
                                              handleNestedSubquestionChange,
                                              topics,
                                              handleMainInstructionChange,
                                              handleDeleteSubquestion,
                                              handleDeleteNestedSubquestion,
                                              handleSubQuestionMemoChange,
                                              handleNestedSubQuestionMemoChange
                                          }) => {

    const [currentSubIndex, setCurrentSubIndex] = useState(0);

    return (
        <div>
            <div>
                {question.sub_questions && question.sub_questions.length > 0 && (
                    <div className="space-between">
                        <div className="editor">
                            <EditorComponent
                                initialData={question.instructions}
                                onChangeReference={handleMainInstructionChange}
                                index={currentSubIndex}
                                placeholder={"Enter Instructions here... "}
                            />
                        </div>
                    </div>
                )}
            </div>

            <div>
                <div>
                    {question.sub_questions.map((subQuestion, subIndex) => (
                        <React.Fragment key={subQuestion.id}>
                            <div style={{flex: 2}}>
                                <div style={{display: 'flex', alignItems: 'baseline', justifyContent: 'space-between'}}>
                                    <div className="space-between">
                                        <IconButton onClick={() => handleDeleteSubquestion(subIndex)}>
                                            <span style={{color: 'red', fontWeight: 'bold', fontSize: '18px'}}>×</span>
                                        </IconButton>
                                        <h3> {`${index + 1}.${subIndex + 1}`}</h3>
                                    </div>

                                    <div className="editor">
                                        <EditorComponent
                                            initialData={subQuestion.text}
                                            onChangeReference={handleSubquestionChange}
                                            index={subIndex}
                                            placeholder={"Please enter your Question here"}
                                        />
                                        {(!subQuestion.nested_sub_questions || subQuestion.nested_sub_questions.length === 0) && (
                                            <div className="memo">
                                                <EditorComponent
                                                    initialData={subQuestion && subQuestion.sub_question_memo?.text ? subQuestion.sub_question_memo.text : ''}
                                                    onChangeReference={handleSubQuestionMemoChange}
                                                    index={subIndex}
                                                    placeholder={"Please enter your answer here"}/>
                                            </div>
                                        )}

                                    </div>
                                    {(!subQuestion.nested_sub_questions || subQuestion.nested_sub_questions.length === 0) && (
                                        <input
                                            type="number"
                                            placeholder="marks"
                                            value={subQuestion.marks}
                                            name="marks"
                                            onChange={e => handleSubQuestionTagChange(e.target.value, e.target.name, subIndex)}
                                            style={{
                                                width: '60px',
                                                height: '20px',
                                                margin: '1px',
                                                border: 'none',
                                                backgroundColor: 'white',
                                                paddingLeft: '10px'
                                            }}
                                        />
                                    )}
                                </div>
                                {(!subQuestion.nested_sub_questions || subQuestion.nested_sub_questions.length === 0) && (
                                    <TagOptionsForm
                                        subIndex={subIndex}
                                        questionType={'subquestion'}
                                        handleTagChange={handleSubQuestionTagChange}
                                        topics={topics}
                                        entity={subQuestion}
                                    />
                                )}
                            </div>
                            {subQuestion.nested_sub_questions && subQuestion.nested_sub_questions.length > 0 && (
                                subQuestion.nested_sub_questions.map((nestedSub, nestedIndex) => (
                                    <NestedQuestionComponent
                                        index={index}
                                        nestedIndex={nestedIndex}
                                        subIndex={subIndex}
                                        handleAddNestedSubquestion={handleAddNestedSubquestion}
                                        handleNestedSubquestionChange={handleNestedSubquestionChange}
                                        handleNestedSubQuestionTagChange={handleNestedSubQuestionTagChange}
                                        topics={topics}
                                        question={question}
                                        handleDeleteNestedSubquestion={handleDeleteNestedSubquestion}
                                        handleNestedSubQuestionMemoChange={handleNestedSubQuestionMemoChange}
                                    />
                                ))
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </div>
            <br/>
            <Grid item>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '1px',
                        flexDirection: 'column',
                    }}
                >
                    <Box
                        onClick={() => {
                            handleAddSubquestion();
                            setCurrentSubIndex(question.sub_questions.length + 1);
                        }} sx={{
                        cursor: 'pointer',
                        color: 'grey',
                        '&:hover': {
                            color: 'black',
                        },
                        textAlign: 'center',
                    }}
                    >
                        <IconButton
                            color="inherit"
                            size="small"
                            sx={{
                                padding: '5px',
                                fontSize: '15px',
                            }}
                        >
                            <AddIcon/>
                        </IconButton>
                        <Typography variant="caption" color="inherit">
                            Add {index + 1}.{currentSubIndex + 1}

                        </Typography>
                    </Box>
                    <Box
                        onClick={() => {
                            handleAddSubquestion()
                            handleAddNestedSubquestion(currentSubIndex);
                            setCurrentSubIndex(question.sub_questions.length + 1);
                        }}
                        sx={{
                            cursor: 'pointer',
                            color: 'grey',
                            '&:hover': {
                                color: 'black',
                            },
                            textAlign: 'center',
                        }}
                    >
                        <IconButton
                            color="inherit"
                            size="small"
                            sx={{
                                padding: '5px',
                                fontSize: '15px',
                            }}
                        >
                            <AddIcon/>
                        </IconButton>
                        <Typography variant="caption" color="inherit">
                            Add {index + 1}.{currentSubIndex + 1} with {index + 1}.{currentSubIndex + 1}.1
                        </Typography>
                    </Box>
                </Box>
            </Grid>
            <br/>
        </div>
    )
};


export default GenericWithSubquestionsComponent;