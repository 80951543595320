import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, LabelList } from 'recharts';
import { Card, CardContent, Typography, Box } from "@mui/material";

const LearnerPerformanceChart = ({ data, viewType }) => {
  // Prepare and sort the data for the Bar Chart
  const chartData = data
    .map((item, index) => {
      let key;
      let value;

      if (viewType === "questions") {
        key = `Question ${index + 1}`;
        value = item.percentage;
      } else if (viewType === "cognitiveLevels") {
        key = item.level_name;
        value = item.percentage;
      } else if (viewType === "topics") {
        key = item.topic_name;
        value = item.percentage;
      } else {
        key = `Item ${index + 1}`;
        value = 0;
      }

      return {
        key,
        percentage: value || 0,
      };
    })
    .sort((a, b) => b.percentage - a.percentage);

  // Set dynamic height based on the number of items
  const chartHeight = Math.max(chartData.length * 50, 300);

  return (
    <Card style={{ backgroundColor: '#FFFFFF', width: '100%', margin: '0 auto' }}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Learner Performance by {viewType.charAt(0).toUpperCase() + viewType.slice(1)}
        </Typography>

        {/* Horizontal Bar Chart for all view types */}
        <Box sx={{ width: '100%' }}>
          <ResponsiveContainer width="100%" height={chartHeight}>
            <BarChart
              layout="vertical"
              data={chartData}
              margin={{ top: 20, right: 40, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" domain={[0, 100]} label={{ value: "Percentage (%)", position: "insideBottom", offset: -5 }} />
              <YAxis type="category" dataKey="key" width={200} />
              <Tooltip />
              <Bar dataKey="percentage" fill="#5F9EA0" barSize={15}>
                <LabelList
                  dataKey="percentage"
                  position="insideRight"
                  formatter={(value) => (value > 0 ? `${value}%` : '')}
                  style={{
                    fill: '#FFF',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </Box>
      </CardContent>
    </Card>
  );
};

export default LearnerPerformanceChart;
