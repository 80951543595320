import axios from "axios";
import getConfig from "../../config/index";

export const ClassTopicResultsApiService = {
    /**
     * Fetch topic details for a given course assessment ID.
     *
     * @param {number} cAId - The course assessment ID.
     * @param {string} token - The authorization token.
     * @returns {Promise<Array>} - The list of topics.
     */
    async fetchTopics(cAId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/course-assessment-results/${cAId}/topics/`;
            const response = await axios.get(url, {
                headers: { Authorization: `Token ${token}` },
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching topics:", error);
            throw error;
        }
    },

    /**
     * Fetch questions for a given topic ID and course assessment ID.
     *
     * @param {number} courseAssessmentId - The course assessment ID.
     * @param {number} topicId - The topic ID.
     * @param {string} token - The authorization token.
     * @returns {Promise<Array>} - The list of questions.
     */
    async fetchQuestions(courseAssessmentId, topicId, token) {
    try {
        const config = getConfig();
        const url = `${config.api.base_url}/course-assessment-results/${courseAssessmentId}/topics/${topicId}/questions/`;
        const response = await axios.get(url, {
            headers: { Authorization: `Token ${token}` },
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching questions:", error);
        throw error;
    }
},


    /**
     * Fetch results for a given course assessment and topic ID.
     *
     * @param {number} courseAssessmentId - The course assessment ID.
     * @param {number} topicId - The topic ID.
     * @param {string} token - The authorization token.
     * @returns {Promise<Object>} - The results for the topic within the given course assessment.
     */
    async fetchResults(courseAssessmentId, topicId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/topic-results/${courseAssessmentId}/${topicId}/`;

            const response = await axios.get(url, {
                headers: { Authorization: `Token ${token}` },
            });

            return response.data;
        } catch (error) {
            console.error(`Error fetching results for CourseAssessment ${courseAssessmentId} and Topic ${topicId}:`, error);
            throw error;
        }
    },


    /**
     * Fetch pass/fail statistics for a given course assessment ID.
     *
     * @param {number} courseAssessmentId - The course assessment ID.
     * @param {string} token - The authorization token.
     * @returns {Promise<Array>} - The pass/fail statistics for each topic.
     */
    async fetchPassFailStats(courseAssessmentId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/class-topic-results/${courseAssessmentId}/pass-fail-stats/`;
            const response = await axios.get(url, {
                headers: { Authorization: `Token ${token}` },
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching pass/fail stats:", error);
            throw error;
        }
    },
};