import React, { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import CourseAssessmentResultsApiService from "../../../services/api/CourseAssessmentResultsApiService";
import { ClassTopicResultsApiService } from "../../../services/api/ClassTopicResultsApiService";
import { ClassQuestionResultsApiService } from "../../../services/api/ClassQuestionResultsApiService";
import { ClassSubQuestionResultsApiService } from "../../../services/api/ClassSubQuestionResultsApiService";

// Styled components for modern design
const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 650,
  overflowX: "auto",
  '& .MuiTableCell-head': {
    fontWeight: 'bold',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  '& .MuiTableCell-body': {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
  marginBottom: theme.spacing(3),
  position: "relative",
  paddingRight: "150px", // ✅ Ensures space on the right for topic names
}));

const ClassPerformanceStats = ({ ciAd }) => {
  const [view, setView] = useState("learners");
  const [learners, setLearners] = useState([]);
  const [percentiles, setPercentiles] = useState([]);
  const [topics, setTopics] = useState([]);
  const [learnerTopicMarks, setLearnerTopicMarks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [percentileLoading, setPercentileLoading] = useState(false);
  const [learnerQuestionMarks, setLearnerQuestionMarks] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [learnerSubQuestionMarks, setLearnerSubQuestionMarks] = useState([]);
  const [subQuestions, setSubQuestions] = useState([]);
  const [topicsStats, setTopicsStats] = useState([]);
  const [chartLoading, setChartLoading] = useState(false);
  const [error, setError] = useState(null);
  const [questionsStats, setQuestionsStats] = useState([]);
  const [subQuestionsStats, setSubQuestionsStats] = useState([]);

  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const { token } = parsedUserInfo;

  useEffect(() => {
    if (view === "learners") {
      const fetchLearners = async () => {
        try {
          const response = await CourseAssessmentResultsApiService.getLearnerMarks(ciAd, token);
          if (response && Array.isArray(response.learners)) {
            setLearners(response.learners);
          } else {
            console.error("API response does not contain 'learners' array:", response);
          }
        } catch (error) {
          console.error("Error fetching learners data:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchLearners();
    } else if (view === "percentiles") {
      const fetchPercentiles = async () => {
        setPercentileLoading(true);
        try {
          const response = await CourseAssessmentResultsApiService.getPercentiles(ciAd, token);
          if (response && response.percentiles) {
            setPercentiles(response.percentiles);
          } else {
            console.error("API response does not contain 'percentiles':", response);
          }
        } catch (error) {
          console.error("Error fetching percentiles:", error);
        } finally {
          setPercentileLoading(false);
        }
      };

      fetchPercentiles();
    } else if (view === "breakdown") {
      const fetchTopicMarks = async () => {
        setLoading(true);
        try {
          const response = await CourseAssessmentResultsApiService.getLearnerMarksPerTopic(ciAd, token);
          if (response && response.learners && response.topics) {
            setLearnerTopicMarks(response.learners);
            setTopics(response.topics);
          } else {
            console.error("API response does not contain learner-topic marks:", response);
          }
        } catch (error) {
          console.error("Error fetching learner-topic marks:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchTopicMarks();
    } else if (view === "questionBreakdown") {
      const fetchQuestionMarks = async () => {
        setLoading(true);
        try {
          const response = await CourseAssessmentResultsApiService.getLearnerMarksPerQuestion(ciAd, token);
          if (response && response.learners && response.questions) {
            setLearnerQuestionMarks(response.learners);
            setQuestions(response.questions);
          } else {
            console.error("API response does not contain learner-question marks:", response);
          }
        } catch (error) {
          console.error("Error fetching learner-question marks:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchQuestionMarks();
    } else if (view === "subQuestionBreakdown") {
      const fetchSubQuestionMarks = async () => {
        setLoading(true);
        try {
          const response = await CourseAssessmentResultsApiService.getLearnerMarksPerSubQuestion(ciAd, token);
          if (response && response.learners && response.subquestions) {
            setLearnerSubQuestionMarks(response.learners);
            setSubQuestions(response.subquestions);
          } else {
            console.error("API response does not contain learner-subquestion marks:", response);
          }
        } catch (error) {
          console.error("Error fetching learner-subquestion marks:", error);
        } finally {
          setLoading(false);
        }
      };

      fetchSubQuestionMarks();
    } else if (view === "topicGraph") {
      const fetchTopicStats = async () => {
        setChartLoading(true);
        try {
          const data = await ClassTopicResultsApiService.fetchPassFailStats(ciAd, token);
          setTopicsStats(data);
        } catch (err) {
          console.error("Error fetching topic stats:", err);
          setError("Failed to load topic statistics.");
        } finally {
          setChartLoading(false);
        }
      };

      fetchTopicStats();
    } else if (view === "questionGraph") {
      const fetchQuestionStats = async () => {
        setChartLoading(true);
        try {
          const data = await ClassQuestionResultsApiService.fetchPassFailStats(ciAd, token);
          setQuestionsStats(data);
        } catch (err) {
          console.error("Error fetching question stats:", err);
          setError("Failed to load question statistics.");
        } finally {
          setChartLoading(false);
        }
      };

      fetchQuestionStats();
    } else if (view === "subQuestionGraph") {
      const fetchSubQuestionStats = async () => {
        setChartLoading(true);
        try {
          const data = await ClassSubQuestionResultsApiService.fetchPassFailStats(ciAd, token);
          setSubQuestionsStats(data);
        } catch (err) {
          console.error("Error fetching subquestion stats:", err);
          setError("Failed to load subquestion statistics.");
        } finally {
          setChartLoading(false);
        }
      };

      fetchSubQuestionStats();
    }
  }, [view, ciAd, token]);

  const handleViewChange = (event) => {
    setView(event.target.value);
  };

  const abbreviateTopic = (topicName) => topicName.split(" ").map(word => word[0]).join("");

  const preparedData = topicsStats.map((item) => ({
    Passed: item.passed_learners ?? 0,
    Failed: item.failed_learners ?? 0,
    topic: abbreviateTopic(item.topic),
    fullTopic: item.topic,
  }));

  // Unique topics for full names at the top right
  const uniqueTopics = [...new Set(preparedData.map(item => item.fullTopic))];

  const prepareQuestionChartData = (data) =>
      data.map((item, index) => ({
        num_learners_passed: item.num_learners_passed ?? 0,
        num_learners_failed: item.num_learners_failed ?? 0,
        question: `Q ${index + 1}`, // ✅ Move names to the right
      }));

  const prepareSubQuestionChartData = (data) =>
      data.map((item) => ({
        Passed: item.num_learners_passed ?? 0,
        Failed: item.num_learners_failed ?? 0,
        subquestion: `Q ${item.subquestion}`, // ✅ Move names to the right
      }));


  const FullTopicContainer = styled(Box)({
    position: "absolute",
    top: 500,
    right: 20, // ✅ Moves to the left to fit better
    textAlign: "left",
    backgroundColor: "white", // ✅ Prevents overlap with the graph
    padding: "5px 10px",
    borderRadius: "5px",
    zIndex: 10,
  });

  return (
    <Box>
      <FormControl fullWidth style={{ marginBottom: 20 }}>
        <Select value={view} onChange={handleViewChange}>
          <MenuItem value="learners">Learners Table</MenuItem>
          <MenuItem value="percentiles">Percentiles Table</MenuItem>
          <MenuItem value="breakdown">Topic Breakdown Table</MenuItem>
          <MenuItem value="questionBreakdown">Question Breakdown Table</MenuItem>
          <MenuItem value="subQuestionBreakdown">SubQuestion Breakdown Table</MenuItem>
          <MenuItem value="topicGraph">Topic Graph</MenuItem>
          <MenuItem value="questionGraph">Question Graph</MenuItem>
          <MenuItem value="subQuestionGraph">Sub-Question Graph</MenuItem>
          {/*<MenuItem value="nestedQuestionGraph">Nested Question Graph</MenuItem>*/}
          {/*<MenuItem value="cognitiveLevelGraph">Cognitive Level Graph</MenuItem>*/}
        </Select>
      </FormControl>

      {view === "learners" && (
        <>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Learners Performance Table
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  This table shows the performance of each learner, including their total marks and whether they passed
                  or failed.
                </Typography>
                <Box sx={{ overflowX: "auto" }}>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>Name/Student Number</TableCell>
                      <TableCell>Marks</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>

<TableBody>
  {learners.map((learner) => (
    <StyledTableRow key={learner.learner_id}>
      {/* Name with Tick/Cross Icon */}
      <TableCell>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          {learner.passed ? (
            <CheckCircleIcon sx={{ color: "green" }} />
          ) : (
            <CancelIcon sx={{ color: "red" }} />
          )}
          <Typography>{learner.first_name} {learner.last_name}</Typography>
        </Box>
      </TableCell>

      {/* Display marks */}
      <TableCell>{learner.total_marks}%</TableCell>

      {/* Status text remains unchanged */}
      <TableCell>
        <Typography sx={{ fontWeight: "bold", color: learner.passed ? "green" : "red" }}>
          {learner.passed ? "Passed" : "Failed"}
        </Typography>
      </TableCell>
    </StyledTableRow>
  ))}
</TableBody>



                </StyledTable>
                </Box>
              </CardContent>
            </StyledCard>
          )}
        </>
      )}

      {view === "percentiles" && (
        <>
          {percentileLoading ? (
            <Typography>Loading...</Typography>
          ) : (
            <StyledCard>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Percentile Distribution Table
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  This table categorizes learners based on their percentile range.
                </Typography>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>Percentile Range</TableCell>
                      <TableCell>Number of Learners</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
  {Object.entries(percentiles).map(([range, count], index) => {
    // Transform percentile label from "percentile_0_29" to "Percentile (0-29)"
    const formattedRange = range.replace("percentile_", "").replace("_", "-");
    return (
      <StyledTableRow key={index}>
        <TableCell>{`Percentile (${formattedRange})`}</TableCell>
        <TableCell>{count}</TableCell>
      </StyledTableRow>
    );
  })}
</TableBody>

                </StyledTable>
              </CardContent>
            </StyledCard>
          )}
        </>
      )}

      {view === "breakdown" && (
        <>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <StyledCard>
              <CardContent>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell>Learner Name</TableCell>
                      {topics.map((topicName, index) => (
                        <TableCell key={index}>{`${topicName}`}</TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {learnerTopicMarks.map((learner) => (
                      <StyledTableRow key={learner.learner_id}>
                        <TableCell>{`${learner.first_name} ${learner.last_name}`}</TableCell>
                        {topics.map((topicName, index) => {
                          const marks = learner.marks[topicName] || { obtained: 0, total: 0 };
                          return (
                            <TableCell key={index}>
                              {`${marks.obtained} / ${marks.total}`}
                            </TableCell>
                          );
                        })}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </CardContent>
            </StyledCard>
          )}
        </>
      )}

      {view === "questionBreakdown" && (
        <>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <StyledCard>
              <CardContent>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Learner Name</b></TableCell>
                      {questions.map((_, index) => (
                        <TableCell key={index}><b>Question {index + 1}</b></TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {learnerQuestionMarks.map((learner) => (
                      <StyledTableRow key={learner.learner_id}>
                        <TableCell>{`${learner.first_name} ${learner.last_name}`}</TableCell>
                        {questions.map((questionId, index) => {
                          const marks = learner.marks[questionId] || { obtained: 0, total: 0 };
                          return (
                            <TableCell key={index}>{`${marks.obtained} / ${marks.total}`}</TableCell>
                          );
                        })}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </CardContent>
            </StyledCard>
          )}
        </>
      )}

      {view === "subQuestionBreakdown" && (
        <>
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <StyledCard>
              <CardContent>
                <StyledTable>
                  <TableHead>
                    <TableRow>
                      <TableCell><b>Learner Name/Student Number</b></TableCell>
                      {subQuestions.map((subq) => (
                        <TableCell key={subq.id}><b>{subq.label}</b></TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {learnerSubQuestionMarks.map((learner) => (
                      <StyledTableRow key={learner.learner_id}>
                        <TableCell>{`${learner.first_name} ${learner.last_name}`}</TableCell>
                        {subQuestions.map((subq) => {
                          const marks = learner.marks[subq.id] || { obtained: 0, total: 0 };
                          return (
                            <TableCell key={subq.id}>{`${marks.obtained} / ${marks.total}`}</TableCell>
                          );
                        })}
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </StyledTable>
              </CardContent>
            </StyledCard>
          )}
        </>
      )}

      {view === "topicGraph" && (
        <StyledCard>
          <FullTopicContainer>
            {uniqueTopics.map((topic, index) => (
                <Typography key={index} variant="body2" color="textPrimary">
                  {abbreviateTopic(topic)} - {topic}
                </Typography>
            ))}
          </FullTopicContainer>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Topic Performance Graph
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              This graph displays the number of learners who passed or failed in each topic, providing a clear overview
              of topic-wise performance in the class.
            </Typography>
            {chartLoading ? (
              <Typography>Loading...</Typography>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
                <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={preparedData}
                    margin={{ top: 20, right: 60, left: 100, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <YAxis type="number" allowDecimals={false} />
                  <XAxis
                      dataKey="topic"
                      type="category"
                      tick={{ fontSize: 14 }}
                  />
                  <Tooltip
                      labelFormatter={(label, payload) => {
                        // Find the full topic based on the abbreviated label
                        const fullTopic = preparedData.find(item => item.topic === label)?.fullTopic;
                        return fullTopic || label;  // Fallback to the label if fullTopic isn't found
                      }}
                  />

                  <Legend wrapperStyle={{ top: 10, right: 10 }} />
                  <Bar dataKey="Passed" name="Passed" fill="#8BBD8B" />
                  <Bar dataKey="Failed" name="Failed" fill="#E74C3C" />
                </BarChart>
              </ResponsiveContainer>
            )}
          </CardContent>
        </StyledCard>
      )}
      {view === "questionGraph" && (
        <StyledCard>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              Question Analysis Graph
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              This graph displays the number of learners who passed or failed each question.
            </Typography>
            {chartLoading ? (
              <Typography>Loading...</Typography>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
                <ResponsiveContainer width="100%" height={400}>
                <BarChart
                    data={prepareQuestionChartData(questionsStats)}
                    margin={{ top: 20, right: 30, left: 150, bottom: 5 }}  // ✅ Adds space for question labels
                >
                  <CartesianGrid strokeDasharray="3 3" />

                  {/* ✅ Place questions on the left */}
                  <YAxis
                      type="number"
                      allowDecimals={false}
                      domain={[0, "dataMax + 1"]}
                  />

                  {/* ✅ Ensure bars extend right */}
                  <XAxis


                      dataKey="question"
                      type="category"
                      tick={{ fontSize: 14, padding: 10 }}
                      width={100}  // ✅ Adjust space for longer labels
                  >

                  </XAxis>

                  <Tooltip />
                  <Legend
                      verticalAlign="bottom"   // ✅ Moves legend to bottom
                      align="center"           // ✅ Centers the legend
                      wrapperStyle={{ marginTop: 20 }}  // ✅ Adds extra space below
                  />

                  <Bar dataKey="num_learners_passed" name="Passed" fill="#8BBD8B" />
                  <Bar dataKey="num_learners_failed" name="Failed" fill="#E74C3C" />
                </BarChart>

              </ResponsiveContainer>
            )}
          </CardContent>
        </StyledCard>
      )}

      {view === "subQuestionGraph" && (
        <StyledCard>
          <CardContent>
            <Typography variant="h6" gutterBottom>
              SubQuestion Graph
            </Typography>
            {chartLoading? (
              <Typography>Loading...</Typography>
            ) : error ? (
              <Typography color="error">{error}</Typography>
            ) : (
                <ResponsiveContainer width="100%" height={400}>
                  <BarChart
                    data={prepareSubQuestionChartData(subQuestionsStats)}
                    margin={{ top: 20, right: 30, left: 150, bottom: 5 }}  // ✅ Space for sub-question labels
                >
                  <CartesianGrid strokeDasharray="3 3" />

                  {/* ✅ Place sub-questions on the left */}
                  <YAxis
                      type="number"
                      allowDecimals={false}
                      domain={[0, "dataMax + 1"]}
                  />

                  {/* ✅ Make bars extend to the right */}
                  <XAxis
                      dataKey="subquestion"
                      type="category"
                      tick={{ fontSize: 14, padding: 10 }}
                      width={100}  // ✅ Adjust space for longer labels
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Passed" name="Passed" fill="#8BBD8B" />
                  <Bar dataKey="Failed" name="Failed" fill="#E74C3C" />
                </BarChart>

              </ResponsiveContainer>
            )}
          </CardContent>
        </StyledCard>
      )}
    </Box>
  );
};

export default ClassPerformanceStats;