import axios from "axios";
import getConfig from "../../config/index";

const BASE_URL = process.env.REACT_APP_API_URL || "http://localhost:8000/api";

export const ClassSubQuestionResultsApiService = {
    async fetchPassFailStats(courseAssessmentId, token) {
        try {
            const config = getConfig();
            const response = await axios.get(
                `${config.api.base_url}/class-subquestion-results/${courseAssessmentId}/pass-fail-stats/`,
                {
                    headers: { Authorization: `Bearer ${token}` },
                },
            );
            return response.data;
        } catch (error) {
            console.error("Error fetching subquestion pass/fail stats:", error);
            throw error;
        }
    },
};
