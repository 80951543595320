import React, { useEffect, useState } from "react";
import "./ClassTopicResults.css";
import { ClassTopicResultsApiService } from "../../../services/api/ClassTopicResultsApiService";
import DisplayContent from "../../../components/displayContent/displayContent";
import { Button } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FeedbackChat from "../../AITool/Chat/AIFeedbackChat";

const ClassTopicResults = ({ cAId }) => {
    const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
    const [topics, setTopics] = useState([]);
    const [questions, setQuestions] = useState({});
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(true); // Track loading status
    const [error, setError] = useState(null); // Track errors

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;


    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedQuestionData, setSelectedQuestionData] = useState({});

    const handleAskAI = (item, type) => {
        setSelectedQuestionData({
            questionId: item.id,
            cAId, // Pass the cAId from props
            type, // 'question', 'sub_question', or 'nested_sub_question'
        });
        setIsModalOpen(true);
    };


    // Fetch topic details
    useEffect(() => {

        if (!cAId || !token) {
            console.warn("useEffect skipped because cAId or token is missing.");
            return;
        }
            ClassTopicResultsApiService.fetchTopics(cAId, token)
                .then((data) => {
                    setTopics(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error("Error fetching topics:", error);
                    setError("Failed to fetch topics.");
                    setLoading(false);
                });
    }, [cAId, token]);


    // Fetch questions and results for each topic
    useEffect(() => {
        if (topics.length > 0) {
            topics.forEach((topic) => {
                // Fetch questions
                ClassTopicResultsApiService.fetchQuestions(cAId, topic.topic_id, token)
                    .then((questionsData) => {
                        setQuestions((prev) => ({
                            ...prev,
                            [topic.topic_id]: questionsData.questions || [], // Ensure it's an array
                        }));
                    })
                    .catch((error) => {
                        console.error("Error fetching questions:", error);
                    });

                // Fetch results
                ClassTopicResultsApiService.fetchResults(cAId, topic.topic_id, token)
                    .then((resultsData) => {
                        setResults((prev) => ({
                            ...prev,
                            [topic.topic_id]: resultsData,
                        }));
                    })
                    .catch((error) => {
                        console.error("Error fetching results:", error);
                    });
            });
        }
    }, [topics, token]);

    // Handle topic selection in the navigation bar
    const handleTopicClick = (index) => {
        setCurrentTopicIndex(index);
    };

    // Get the current topic
    const currentTopic = topics[currentTopicIndex];

    if (loading) {
        return (
            <div className="loading-container">
                <div className="loading-spinner"></div>
            </div>
        );
    }

    if (error) {
        return (
            <div className="error-container">
                <p>Error: {error}</p>
            </div>
        );
    }

    return (
        <div className="class-topic-results-container">
            {/* Cards Section */}
            {currentTopic && (
                <div className="cards">
                    {/* Average Mark */}
                    <div className="card average-mark">
                        <h3>Average Mark</h3>
                        <p>
                            {results[currentTopic.topic_id]?.average_mark || 0}% Average Mark (
                            {results[currentTopic.topic_id]?.total_learners || 0} Learners Wrote)
                        </p>
                    </div>

                    {/* Pass Rate */}
                    <div className="card pass-rate">
                        <h3>Pass Rate</h3>
                        <p>
                            {results[currentTopic.topic_id]?.pass_percentage || 0}% Pass Rate (
                            {results[currentTopic.topic_id]?.passed_learners || 0}/
                            {results[currentTopic.topic_id]?.total_learners || 0} Learners Passed)
                        </p>
                    </div>

                    {/* Fail Rate */}
                    <div className="card fail-rate">
                        <h3>Fail Rate</h3>
                        <p>
                            {results[currentTopic.topic_id]?.fail_percentage || 0}% Fail Rate (
                            {results[currentTopic.topic_id]?.failed_learners || 0}/
                            {results[currentTopic.topic_id]?.total_learners || 0} Learners Failed)
                        </p>
                    </div>
                </div>
            )}

            {/* Navigation Bar */}
            <nav className="navbar">
                <ul>
                    {topics.map((topic, index) => (
                        <li
                            key={topic.topic_id}
                            className={index === currentTopicIndex ? "active" : ""}
                            onClick={() => handleTopicClick(index)}
                            >
                            {topic.topic_name}
                        </li>
                    ))}
                </ul>
            </nav>


            {/* Table Section */}
            {currentTopic && (
                <div className="modern-table">
                    <table>
                        <thead>
                        <tr>
                            {/*<th>Topic (Term & Week)</th>*/}
                            <th>Questions</th>
                            <th>Feedback</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            {/*<td>*/}
                            {/*  <div className="topic-cell">*/}
                            {/*    <h3 className="topic-name">{currentTopic.name}</h3>*/}
                            {/*    <p>*/}
                            {/*        <b>Term: {currentTopic.term}, Week: {currentTopic.week}</b>*/}
                            {/*    </p>*/}
                            {/*    <p>*/}
                            {/*        <b>Description:</b>*/}
                            {/*        <DisplayContent content={currentTopic.description} />*/}
                            {/*    </p>*/}
                            {/*    <p>*/}
                            {/*        <b>Prerequisite:</b>*/}
                            {/*        <DisplayContent content={currentTopic.prerequisite} />*/}
                            {/*    </p>*/}
                            {/*  </div>*/}
                            {/*</td>*/}
                            <td>
                                {/* Render questions with question numbers if there is text or instructions */}
                                {questions[currentTopic.topic_id]?.map((question) => (
                                    <div key={`question-${question.question_number}`}>
                                        {/* Render question if it has text or instructions */}
                                        {(question.text || question.instructions) && (
                                            <div className="question-container">
                                                <p>
                                                    <div className="question-row">
                        <span className="question-number">
                            <b>{question.question_number}.</b>
                        </span>
                                                        <span className="question-text">
                            {question.text && <DisplayContent content={question.text} />}
                        </span>

                                                        {/* ASK AI Button only if no sub-questions */}
                                                        {(!question.sub_questions || question.sub_questions.length === 0) && question.text && (
                                                            <Button
                                                                variant="text"
                                                                startIcon={<AutoAwesomeIcon />}
                                                                onClick={() => handleAskAI(question.question_number, question.text)}
                                                                className="ask-ai-button"
                                                                sx={{
                                                                    backgroundColor: "white",
                                                                    boxShadow: "none",
                                                                    border: "none",
                                                                    color: "#5F9EA0",
                                                                    "&:hover": {
                                                                        backgroundColor: "#f0f0f0", // subtle hover effect
                                                                        boxShadow: "none",
                                                                    },
                                                                }}
                                                            >
                                                                Ask AI
                                                            </Button>
                                                        )}
                                                    </div>
                                                </p>

                                                {question.instructions && (
                                                    <div className="instructions-container">
                                                        <b>Instructions:</b>
                                                        <DisplayContent content={question.instructions} />
                                                    </div>
                                                )}
                                            </div>
                                        )}

                                        {questions[currentTopic.topic_id]?.map((question) => (
                                            <div key={`question-${question.question_number}`}>
                                                {(question.text || question.instructions) && (
                                                    <div className="question-container">
                                                        <div className="question-row">
                                                        <span className="question-number">
                                                            <b>{question.question_number}.</b>
                                                        </span>
                                                            <span className="question-text">
                                                            {question.text &&
                                                                <DisplayContent content={question.text} />}
                                                        </span>
                                                            {(!question.sub_questions || question.sub_questions.length === 0) && question.text && (
                                                                <Button
                                                                    variant="text"
                                                                    startIcon={<AutoAwesomeIcon />}
                                                                    onClick={() => handleAskAI(question, "question")}
                                                                    sx={{
                                                                        backgroundColor: "white",
                                                                        boxShadow: "none",
                                                                        border: "none",
                                                                        color: "#5F9EA0",
                                                                        "&:hover": {
                                                                            backgroundColor: "#f0f0f0",
                                                                            boxShadow: "none",
                                                                        },
                                                                    }}
                                                                >
                                                                    Ask AI
                                                                </Button>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}

                                                {question.sub_questions?.map((subQuestion) => (
                                                    <div key={`sub-question-${subQuestion.sub_question_number}`}>
                                                        {(subQuestion.text || subQuestion.instructions) && (
                                                            <div className="sub-question-row">
                                                            <span className="sub-question-number">
                                                                <b>{subQuestion.sub_question_number}.</b>
                                                            </span>
                                                                <span className="sub-question-text">
                                                                {subQuestion.text &&
                                                                    <DisplayContent content={subQuestion.text} />}
                                                            </span>
                                                                {(!subQuestion.nested_sub_questions || subQuestion.nested_sub_questions.length === 0) && subQuestion.text && (
                                                                    <Button
                                                                        variant="text"
                                                                        startIcon={<AutoAwesomeIcon />}
                                                                        onClick={() => handleAskAI(subQuestion, "sub_question")}
                                                                        sx={{
                                                                            backgroundColor: "white",
                                                                            boxShadow: "none",
                                                                            border: "none",
                                                                            color: "#5F9EA0",
                                                                            "&:hover": {
                                                                                backgroundColor: "#f0f0f0",
                                                                                boxShadow: "none",
                                                                            },
                                                                        }}
                                                                    >
                                                                        Ask AI
                                                                    </Button>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                ))}

                            </td>
                            <td>
                                <DisplayContent
                                    content={results[currentTopic.topic_id]?.practical_explanations || ""} />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            )}
            <FeedbackChat
                open={isModalOpen}
                handleClose={() => setIsModalOpen(false)}
                cAId={selectedQuestionData.cAId}
                type={selectedQuestionData.type}
                questionId={selectedQuestionData.questionId}
            />
        </div>
    );
};

export default ClassTopicResults;