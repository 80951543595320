import { useEffect } from "react";
import "./landingPage.css";

const LandingPage = () => {

    useEffect(() => {
        // Redirect to the desired URL
        window.location.href = "https://velle.educasetools.com/";
    }, []);

    // const landingPageRef = useRef(null);
    // const calendlyRef = useRef(null);
    //
    // // State to track the screen size
    // const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 750);
    //
    // // Function to update screen size on resize
    // const handleResize = () => {
    //     setIsSmallScreen(window.innerWidth <= 750);
    // };
    //
    // useEffect(() => {
    //     // Add event listener to handle window resize
    // window.addEventListener('resize', handleResize);
    //
    //     // Clean up the event listener when the component is unmounted
    //     return () => {
    //   window.removeEventListener('resize', handleResize);
    //     };
    // }, []);
    //
    // const scrollToCalendly = () => {
    // calendlyRef.current.scrollIntoView({ behavior: 'smooth' });
    // };
    //
    // return (
    //     <div className="landing-page-container" ref={landingPageRef}>
    //         <Header />
    //         <HeroSection scrollToCalendly={scrollToCalendly} />
    //         <Scenarios />
    //         {/* Conditional rendering based on screen size */}
    //         {isSmallScreen ? <Features /> : <FeatureSlider />}
    //
    //         {/* Testing Process Value Section shown only on small screens */}
    //         <TestingProcessValue />
    //
    //         <UserRolesSlider />
    //         {/* Testimonials Section */}
    //         <Testimonials />
    //         {/* Pricing Plans Section */}
    //         <PricingCards />
    //         <Footer />
    //     </div>
    // );
};

export default LandingPage;
