import React from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

const SummaryCard = ({ title, value, description }) => (
  <Card sx={{
    width: 280, // Adjust width to match the screenshot
    height: 100, // Shorter height
    backgroundColor: 'white',
    borderRadius: 12, // Softer edges
    padding: 1.5,
    boxShadow: 2,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center"
  }}>
    <CardContent sx={{ padding: "10px", width: "100%" }}>
      <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
        {title}
      </Typography>
      <Typography variant="h6" sx={{ fontWeight: "bold", mt: 0.5 }}>
        {value}
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ mt: 0.5, fontSize: "0.85rem" }}>
        {description}
      </Typography>
    </CardContent>
  </Card>
);

const SummaryCards = ({
  averageMark,
  totalLearners,
  totalSubmitted,
  totalPassed,
  totalFailedWithNonSubmit,
  passedPercentage,
  failedPercentage,
}) => {
  return (
    <Box display="flex" justifyContent="center" gap={2} flexWrap="wrap">
      <SummaryCard
        title="Average Mark"
        value={`${averageMark}%`}
        description={`${totalSubmitted} Learners Wrote`}
      />
      <SummaryCard
        title="Pass Rate"
        value={`${passedPercentage}%`}
        description={`${totalPassed}/${totalSubmitted} Learners Passed`}
      />
      <SummaryCard
        title="Fail Rate"
        value={`${failedPercentage}%`}
        description={`${totalFailedWithNonSubmit}/${totalSubmitted} Learners Failed`}
      />
    </Box>
  );
};

export default SummaryCards;
