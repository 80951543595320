import axios from "axios";
import getConfig from "../../config/index";

export const ClassQuestionResultsApiService = {
    async fetchPassFailStats(courseAssessmentId, token) {
        try {
            const config = getConfig();
            const url = `${config.api.base_url}/class-question-results/${courseAssessmentId}/pass-fail-stats/`;
            const response = await axios.get(url, {
                headers: { Authorization: `Token ${token}` },
            });
            return response.data;
        } catch (error) {
            console.error("Error fetching question pass-fail stats:", error);
            throw error;
        }
    },
};
