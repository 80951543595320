import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Button,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SchoolIcon from "@mui/icons-material/School";
import EditIcon from "@mui/icons-material/Edit";
import DisplayContent from "../../../components/displayContent/displayContent";
import { OnlineTestApiService } from "../../../services/api/OnlineTestAPIService";
import {FeedbackApiService} from "../../../services/api/FeedbackApiService";
import AssessmentAPIService from "../../../services/api/AssessmentAPIService";
import Swal from "sweetalert2";
import {useNavigate} from "react-router-dom";

// Helper function to determine the level
const getLevel = (mark, total) => {
  const percentage = (mark / total) * 100;
  if (percentage >= 80) return "Challenge";
  if (percentage >= 50) return "Reinforcement";
  return "Basics";
};

const PersonalizedQuestionPaper = ({ totalMark, classAverage, onlineTestId, onClose }) => {
  const [questions, setQuestions] = useState([]);
  const [editingField, setEditingField] = useState({ index: null, field: null });
  const [loading, setLoading] = useState(true);
    const navigate = useNavigate();


  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");
  const { token } = parsedUserInfo;



  const handleSaveField = async (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);

    // Call updateAnalysis for the "analysis" field
    if (field === "analysis") {
        const feedbackId = updatedQuestions[index].feedbackId; // Ensure each question has feedbackId
        if (feedbackId) {
            try {
                const response = await FeedbackApiService.updateAnalysis(
                    { feedback_id: feedbackId, analysis: value },
                    token
                );
                if (!response || response.error) {
                    console.error("Error updating analysis:", response ? response.error : "Unknown error");
                }
            } catch (error) {
                console.error("Error updating analysis:", error);
            }
        } else {
            console.error("Feedback ID is missing for question:", updatedQuestions[index]);
        }
    }

    setEditingField({ index: null, field: null });
};




  useEffect(() => {
    const fetchQuestions = async () => {
  try {
    const results = await OnlineTestApiService.fetchLearnerResults(onlineTestId, token);
    if (results && results.learner_results) {
      const formattedQuestions = results.learner_results.map((q) => ({
        questionNumber: q.question_number,
        instructions: q.text,
        analysis: q.analysis || "No analysis available",
        mark: q.allocated_marks || 0,
        total: q.marks || 0,
        level: getLevel(q.allocated_marks || 0, q.marks || 0),
        feedbackId: q.feedback_id, // Ensure this field is included in the API response
      }));
      setQuestions(formattedQuestions);
    }
  } catch (error) {
    console.error("Error fetching learner results:", error);
  } finally {
    setLoading(false);
  }
};

    fetchQuestions();
  }, [onlineTestId, token]);

  // Calculate total allocated marks and total possible marks
  const totalAllocatedMarks = questions.reduce((sum, question) => sum + question.mark, 0);
  const totalPossibleMarks = questions.reduce((sum, question) => sum + question.total, 0);

  const handleGeneratePDF = async () => {
  try {
    const examId = "9"; // Replace with the actual exam ID when available
    const response = await AssessmentAPIService.fetchAssessment(examId, token);

    if (response) {
      const assessmentDetails = {
        examId: response.id,
        name: response.name,
        subjectName: response.subject.name,
        subjectId: response.subject.id,
        grade: response.grade,
        type: response.type,
        date: response.assessment_date,
        questions: response.questions || [],
        instructions: response.instructions,
      };

      localStorage.removeItem("assessmentDetails");
      localStorage.setItem(
        "assessmentDetails",
        JSON.stringify(assessmentDetails)
      );

      navigate("/create-assessment", { state: { assessmentDetails } });
    } else {
      console.error("Failed to fetch assessment data.");
      await Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to fetch assessment data. Please try again later.",
      });
    }
  } catch (error) {
    console.error("Error fetching assessment data:", error);
    await Swal.fire({
      icon: "error",
      title: "Error",
      text: "An unexpected error occurred. Please try again later.",
    });
  }
};


  return (
    <Dialog open={true} fullWidth maxWidth="xl" onClose={onClose}>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5">Personalized Question Paper</Typography>
          <Box>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SchoolIcon />}
              onClick={handleGeneratePDF}
              sx={{ marginRight: 1 }}
            >
              Generate Personalized Question Paper
            </Button>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Typography variant="body1">Loading questions...</Typography>
        ) : (
          <Box>
            {/* Explanation of Levels */}
            <Box mb={3}>
              <Typography variant="h6">Explanation of Levels</Typography>
              <ul>
                <li>
                  <strong>Basics:</strong> Achieved less than 50% of the allocated marks.
                  Revisit the fundamental concepts of the question.
                </li>
                <li>
                  <strong>Reinforcement:</strong> Achieved between 50% and 79% of the allocated marks.
                  Practice questions of the same level to strengthen understanding.
                </li>
                <li>
                  <strong>Challenge:</strong> Achieved 80% or more of the allocated marks.
                  Attempt more advanced questions to push boundaries.
                </li>
              </ul>
            </Box>


            {/* Learner Stats */}
            <Box display="flex" justifyContent="space-between" mb={3}>
              <Typography variant="body1">
                <strong>Total Mark:</strong> {totalMark} ({totalAllocatedMarks}/{totalPossibleMarks})
              </Typography>
              <Typography variant="body1">
                <strong>Class Average:</strong> {classAverage}
              </Typography>
            </Box>

            {/* Question Table */}
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell><strong>Question Number</strong></TableCell>
                    <TableCell><strong>Question</strong></TableCell>
                    <TableCell><strong>Analysis</strong></TableCell>
                    <TableCell><strong>Mark / Total</strong></TableCell>
                    <TableCell><strong>Level</strong></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                {questions.map((question, index) => (
                  <TableRow key={index}>
                    <TableCell>{question.questionNumber}</TableCell>
                    <TableCell>
                      <DisplayContent content={question.instructions} />
                    </TableCell>
                    <TableCell>
                      {editingField.index === index && editingField.field === "analysis" ? (
                        <TextField
                          multiline
                          rows={4}
                          fullWidth
                          defaultValue={question.analysis}
                          onBlur={(e) => handleSaveField(index, "analysis", e.target.value)}
                          autoFocus
                        />
                      ) : (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <DisplayContent content={question.analysis} />
                          <IconButton
                            size="small"
                            onClick={() => setEditingField({ index, field: "analysis" })}
                            sx={{ marginLeft: 1 }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      )}
                    </TableCell>
                    <TableCell>{`${question.mark} / ${question.total}`}</TableCell>
                    <TableCell>
                      {editingField.index === index && editingField.field === "level" ? (
                        <Select
                          value={question.level}
                          onChange={(e) => handleSaveField(index, "level", e.target.value)}
                          onBlur={() => setEditingField({ index: null, field: null })}
                          autoFocus
                        >
                          <MenuItem value="Basics">Basics</MenuItem>
                          <MenuItem value="Reinforcement">Reinforcement</MenuItem>
                          <MenuItem value="Challenge">Challenge</MenuItem>
                        </Select>
                      ) : (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {question.level}
                          <IconButton
                            size="small"
                            onClick={() => setEditingField({ index, field: "level" })}
                            sx={{ marginLeft: 1 }}
                          >
                            <EditIcon fontSize="small" />
                          </IconButton>
                        </Box>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>

              </Table>
            </TableContainer>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PersonalizedQuestionPaper;
