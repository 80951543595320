/** @constant
    @type {object}
    @default
*/

//Prod IP
// 'https://api.educasetools.com/api',


//Test IP
//https://172.104.133.9/api === https://onlinetestapi.educasetools.com/api/



const config = {
  production: {
    api: {
      base_url: 'https://172.104.133.9/api',
      fe_base_url: 'https://www.educasetools.com'
    },
    rollbar: {
      accessToken: 'ca385c0224644db3937fe0445d11d73e',
      environment: 'prod',
    },
    adobePdfEmbed: {
      clientId: "f028cb8258e64ee89910eebcf1355575"
    }
  },
  development: {
    api: {
      base_url: 'http://127.0.0.1:8000/api',
      fe_base_url: 'http://localhost:3000'
    },
    rollbar: {
      accessToken: 'ca385c0224644db3937fe0445d11d73e',
      environment: 'testenv',
    },
    adobePdfEmbed: {
      clientId: "252d2b55da9e41238c7ecea89f59eefb"
    }
  },
  staging: {
    api: {
      base_url: 'https://dev.educasetools.com/api',
      fe_base_url: 'https://educase-drag-n-drop-118179097d24.herokuapp.com',
      rollbar: {
      accessToken: 'ca385c0224644db3937fe0445d11d73e',
      environment: 'testenv',
    },
    adobePdfEmbed: {
      clientId: "d5a524b248f940a9b9401af3ea340736"
    }
    },
  },
};

/**
 * Get env based configuration
 * @constructor
 * @returns {configuration}
 */
export default function getConfig() {
  const environment = process.env.NODE_ENV;

  if (['production', 'development', 'staging'].includes(environment)) {
    return config[environment];
  }
  throw new Error(`invalid value for NODE_ENV: ${environment}`);
}