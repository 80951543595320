import React, {useEffect, useRef, useState} from "react";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import "./index.css";
import QuestionApiService from "../../services/api/questionAPIService";
import GenericWithSubquestionsComponent from "./genericWithSubquestionsComponent";
import Chip from "@mui/material/Chip";
import {initialQuestionState} from "../../data/constants/initialQuestionState";


const CreateQuestionComponent = ({
                                     index,
                                     emptyQuestion,
                                     OnAddQuestion,
                                     setIsCreateQuestion,
                                     removeQuestionFromAssessmentPaper,
                                     grade,
                                     subjectId
                                 }) => {

    const containerRef = useRef(null);

    const [topics, setTopics] = useState([]);
    const [isUpdating, setIsUpdating] = useState(false);
    const [question, setQuestion] = useState(() => ({...emptyQuestion}));
    const [renderCreateQuestionComponent, setRenderCreateQuestionComponent] = useState(false);
    const [questionType, setQuestionType] = useState();


    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const {userId} = parsedUserInfo;

    const resetQuestionState = () => {
        setQuestion({...initialQuestionState});
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const handleAddSubquestion = () => {
        setQuestion((prevState) => {
            const newSubquestion = {
                id: (prevState.sub_questions.length + 1).toString(),
            };

            return {
                ...prevState,
                sub_questions: [...prevState.sub_questions, newSubquestion],
                published_by: userId,
                updated_by: userId
            };
        })
    };

    const handleAddNestedSubquestion = (subIndex) => {
        setQuestion((prevState) => {
            const nested_sub_questions = {
                text: null,
                nested_sub_question_memo: {
                    text: null,
                    instructions: null,
                    published_by: userId,
                    updated_by: userId
                },
            };

            // Make a copy of the previous state's sub_questions
            const updatedSubQuestions = [...prevState.sub_questions];

            // Add the new nested subquestion to the specific sub_question array
            if (updatedSubQuestions[subIndex]) {
                if (updatedSubQuestions[subIndex].nested_sub_questions) {
                    updatedSubQuestions[subIndex].nested_sub_questions.push(nested_sub_questions);
                } else {
                    updatedSubQuestions[subIndex].nested_sub_questions = [nested_sub_questions];
                }

            } else if (updatedSubQuestions[subIndex - 1]) {
                if (updatedSubQuestions[subIndex - 1].nested_sub_questions) {
                    updatedSubQuestions[subIndex - 1].nested_sub_questions.push(nested_sub_questions);
                } else {
                    updatedSubQuestions[subIndex - 1].nested_sub_questions = [nested_sub_questions];
                }
            }

            // Return the new state
            return {
                ...prevState,
                sub_questions: updatedSubQuestions,
                published_by: userId,
                updated_by: userId,
            };
        });
    };

    const handleDiscardQuestion = async () => {
        setIsCreateQuestion(false);
        resetQuestionState();
        removeQuestionFromAssessmentPaper(index)
    }

    const calculateTotalMarks = (question) => {
        let marks = Number(question.marks) || 0;

        // Iterate over sub-questions
        question.sub_questions.forEach(subQuestion => {
            marks += Number(subQuestion.marks) || 0;

            // Iterate over nested sub-questions of the current sub-Question
            (subQuestion.nested_sub_questions || []).forEach(nestedSubQuestion => {
                marks += Number(nestedSubQuestion.marks) || 0;
            });
        });
        return marks;
    };

    const handleSaveQuestion = async () => {
        const total_marks = calculateTotalMarks(question);

        // Update the Question state with marks
        const questionWithTotalMarks = {
            ...question,
            total_marks
        };


        try {
            const userInfo = localStorage.getItem("user");
            const parsedUserInfo = JSON.parse(userInfo || "{}");
            const {token} = parsedUserInfo;

            setIsUpdating(true);

            // Create Question
            const response = await QuestionApiService.createQuestion(questionWithTotalMarks, token);
            if (response.ok) {
                const res = await response.json();
                OnAddQuestion(res, index)
                resetQuestionState();

                Toast.fire({
                    icon: "success",
                    title: "Question Successfully created.",
                })
            } else {
                const res = await response.json();
                // Handle API error by displaying it to the user
                Toast.fire({
                    icon: "error",
                    title: res.error || "Failed to save Question.",
                });
            }
        } catch (error) {
            console.error(error);
            // Handle general error by displaying it to the user
            Toast.fire({
                icon: "error",
                title: "An error occurred while saving the Question.",
            });
        }

        setIsUpdating(false);
    }

    const handleSubInstructionChange = (data, index) => {
        setQuestion((prevState) => {
            const updatedSubQuestions = [...prevState.sub_questions];
            // Update the instruction of the specific sub-Question at the index
            updatedSubQuestions[index - 1].instructions = data;

            return {
                ...prevState,
                sub_questions: updatedSubQuestions
            };
        });
    };

    const handleMainInstructionChange = (data, index = 0) => {
        setQuestion((prevState) => ({
            ...prevState,
            instructions: data
        }));
    };

    const handleNestedSubquestionChange = (content, subQuestionIndex, nestedSubQuestionIndex) => {
        setQuestion((prevState) => {
            const updatedSubQuestions = prevState.sub_questions.map((sub_question, index) => {
                if (index === subQuestionIndex) {
                    const updatedNestedSubQuestions = sub_question.nested_sub_questions.map((nested_sub_questions, nestedIndex) =>
                        nestedIndex === nestedSubQuestionIndex ?
                            {
                                ...nested_sub_questions,
                                text: content,
                                updated_by: userId
                            } : nested_sub_questions,
                    );
                    return {...sub_question, nested_sub_questions: updatedNestedSubQuestions};
                }
                return sub_question;
            });

            return {
                ...prevState,
                sub_questions: updatedSubQuestions,
                updated_by: userId
            };
        });
    };

    const handleNestedSubQuestionMemoChange = (content, subQuestionIndex, nestedSubQuestionIndex) => {
        setQuestion((prevState) => {
            const updatedSubQuestions = prevState.sub_questions.map((sub_question, index) => {
                if (index === subQuestionIndex) {
                    const updatedNestedSubQuestions = sub_question.nested_sub_questions.map((nested_sub_question, nestedIndex) => {
                        if (nestedIndex === nestedSubQuestionIndex) {
                            const updatedNestedSubQuestion = {
                                ...nested_sub_question,
                                nested_sub_question_memo: {
                                    text: content,
                                    updated_by: userId
                                }
                            }
                            // Log the updated nested sub-Question
                            return updatedNestedSubQuestion;
                        } else {
                            return nested_sub_question;
                        }
                    });
                    return {...sub_question, nested_sub_questions: updatedNestedSubQuestions};
                }
                return sub_question;
            });
            return {
                ...prevState,
                sub_questions: updatedSubQuestions,
                updated_by: userId
            };
        })
    };

    const handleSubquestionChange = (data, subQuestionIndex) => {
        setQuestion((prevState) => {
            const updatedSubQuestions = prevState.sub_questions.map((sub_question, index) =>
                index === subQuestionIndex ? {...sub_question, text: data} : sub_question,
            );

            return {
                ...prevState,
                sub_questions: updatedSubQuestions,
                updated_by: userId,
            };
        });
    };

    const handleSubQuestionMemoChange = (data, subQuestionIndex) => {
        setQuestion((prevState) => {
            const updatedSubQuestions = prevState.sub_questions.map((sub_question, index) => {
                if (index === subQuestionIndex) {
                    // Clone the sub_question object and then update the memo.text
                    return {
                        ...sub_question,
                        sub_question_memo: {
                            ...sub_question.sub_question_memo,
                            text: data,
                            updated_by: userId
                        }
                    };
                }
                return sub_question;
            });

            // Now return the updated state with the new array of sub_questions
            return {
                ...prevState,
                sub_questions: updatedSubQuestions,
                updated_by: userId
            };
        });
    };

    const handleChipClick = (nature) => {
        setQuestionType(nature)
        setRenderCreateQuestionComponent(true)
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await QuestionApiService.fetchTopics(grade, subjectId);
            if (response.ok) {
                const data = await response.json();
                setTopics(data);
            } else {
                console.error("Failed to fetch topics");
            }
        };
        fetchData();
    }, [grade, subjectId]);

    // const handleQuestionTagChange = (tag_value, tag_name, index) => {
    //   const updatedQuestion = { ...question };
    //   // Update the level of the specified sub-Question
    //   updatedQuestion[tag_name] = tag_value;
    //
    //   // Update the Question state
    //   setQuestion(updatedQuestion);
    // };

    const handleSubQuestionTagChange = (tag_value, tag_name, subIndex) => {
        const updatedQuestion = {...question};
        updatedQuestion.sub_questions = [...updatedQuestion.sub_questions];
        updatedQuestion.sub_questions[subIndex] = {...updatedQuestion.sub_questions[subIndex]};
        updatedQuestion.sub_questions[subIndex][tag_name] = tag_value;
        setQuestion(updatedQuestion);
    };

    const handleNestedSubQuestionTagChange = (tag_value, tag_name, subIndex, nestedIndex) => {
        setQuestion(prevState => {
            // Deep copy of the previous state
            const updatedQuestion = JSON.parse(JSON.stringify(prevState));

            // Now, we can safely modify our copy
            if (updatedQuestion.sub_questions[subIndex] && updatedQuestion.sub_questions[subIndex].nested_sub_questions[nestedIndex]) {
                updatedQuestion.sub_questions[subIndex].nested_sub_questions[nestedIndex][tag_name] = tag_value;
            }

            // Return the updated copy to be used as the new state
            return updatedQuestion;
        });
    };

    const handleDeleteSubquestion = (subIndexToDelete) => {
        setQuestion(prevState => {
            const updatedSubQuestions = prevState.sub_questions.filter((_, index) => index !== subIndexToDelete);
            return {
                ...prevState,
                sub_questions: updatedSubQuestions
            };
        });
    };

    const handleDeleteNestedSubquestion = (subIndex, nestedIndexToDelete) => {
        setQuestion(prevState => {
            const updatedSubQuestions = [...prevState.sub_questions];
            updatedSubQuestions[subIndex].nested_sub_questions =
                updatedSubQuestions[subIndex].nested_sub_questions.filter((_, index) => index !== nestedIndexToDelete);

            return {
                ...prevState,
                sub_questions: updatedSubQuestions
            };
        });
    };

    useEffect(() => {
        const checkContainerSize = () => {
            if (containerRef.current) {
            }
        };
        checkContainerSize();
    }, [question]);

    const renderQuestionComponent = () => {
        switch (questionType) {
            case 'GenericWithSubquestions':
                return <GenericWithSubquestionsComponent
                    index={index}
                    question={question}
                    handleAddSubquestion={handleAddSubquestion}
                    handleSubInstructionChange={handleSubInstructionChange}
                    handleSubQuestionTagChange={handleSubQuestionTagChange}
                    handleMainInstructionChange={handleMainInstructionChange}
                    handleSubquestionChange={handleSubquestionChange}
                    handleAddNestedSubquestion={handleAddNestedSubquestion}
                    handleNestedSubQuestionTagChange={handleNestedSubQuestionTagChange}
                    handleNestedSubquestionChange={handleNestedSubquestionChange}
                    handleDeleteSubquestion={handleDeleteSubquestion}
                    handleDeleteNestedSubquestion={handleDeleteNestedSubquestion}
                    handleSubQuestionMemoChange={handleSubQuestionMemoChange}
                    handleNestedSubQuestionMemoChange={handleNestedSubQuestionMemoChange}
                    topics={topics}
                />;
            default:
                return null;
        }
    };


    return (
        <div style={{position: 'relative'}}>
            <div style={{position: 'absolute', top: 0, right: 0}}>
                {renderCreateQuestionComponent && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSaveQuestion}
                        style={{marginRight: '10px', height: '25px'}}
                        disabled={isUpdating}
                    >
                        {isUpdating ? "Saving..." : "Save"}
                    </Button>
                )}
                <Button
                    variant="contained"
                    onClick={handleDiscardQuestion}
                    style={{height: '25px', backgroundColor: '#004d40'}}
                >
                    Discard
                </Button>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <h3>QUESTION {index + 1}</h3>
                {renderCreateQuestionComponent ? (
                    renderQuestionComponent()
                ) : (
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <Chip label="Questions with sub-questions"
                              onClick={() => handleChipClick('GenericWithSubquestions')} color="primary"
                              style={{margin: '8px'}}/>
                    </div>
                )}
            </div>
        </div>
    );
};

export default CreateQuestionComponent;
