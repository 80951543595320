import React, { useEffect, useState } from "react";
import './TutoringCoverPage.css';

const logoUrl = "https://assets.educasetools.com/Velle.png";

const TutoringCoverPage = ({ testName, grade, subjectName, date, totalMarks }) => {
  const [tutor, setTutor] = useState('');

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.name) {
      setTutor(user.name);
    }
  }, []);

  return (
    <div className="tutoring-cover-page">
      <div className="tutoring-cover-page-content">
        <div className="tutoring-cover-page-details">
          <div className="tutoring-cover-page-detail-item"><strong>{testName}</strong></div>
          <div className="tutoring-cover-page-detail-item"><strong>Tutor: </strong> {tutor}</div>
          <div className="tutoring-cover-page-detail-item"><strong>Grade: </strong> {grade}</div>
          <div className="tutoring-cover-page-detail-item"><strong>Subject: </strong> {subjectName}</div>
          <div className="tutoring-cover-page-detail-item"><strong>Total marks: </strong> {totalMarks} marks</div>
          <div className="tutoring-cover-page-detail-item"><strong>Date: </strong> {date}</div>
        </div>
        <div className="tutoring-cover-page-logo-container">
          <img src={logoUrl} alt="Tutoring Company Logo" className="tutoring-cover-page-logo" />
        </div>
      </div>
    </div>
  );
};

export default TutoringCoverPage;
