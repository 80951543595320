import React, { useEffect, useState } from "react";
import { Box, Button, Card, CircularProgress, Container, Grid, IconButton, useMediaQuery } from "@mui/material";
import SummaryCards from "./SummaryCard/SummaryCards";
import CourseAssessmentResultsApiService from "../../services/api/CourseAssessmentResultsApiService";
import { useNavigate, useParams } from "react-router-dom";
import ClassPerformanceStats from "./LearnerPerformanceStats/ClassPerformanceStats";
import LearnerResultAnalysis from "./LearnerResultAnalysis/LearnerResultAnalysis";
import BreadcrumbNavigation from "./BreadcrumbNavigation";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import HomeIcon from "@mui/icons-material/Home";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ClassTopicResults from "./ClassTopicResults/ClassTopicResults";

const StatsPage = () => {
    const { cAId } = useParams();
    const [assessmentSummary, setAssessmentSummary] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedLearner, setSelectedLearner] = useState(null);
    const [currentView, setCurrentView] = useState("summary");
    const [showClassTopicResults, setShowClassTopicResults] = useState(false);

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;
    const navigate = useNavigate();
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    useEffect(() => {
        if (cAId && token) {
            CourseAssessmentResultsApiService.getAssessmentSummary(cAId, token)
                .then((response) => {
                    if (response) {
                        setAssessmentSummary(response);
                    } else {
                        console.log("Response is empty or invalid:", response);
                        setAssessmentSummary(null);
                    }
                })
                .catch((err) => {
                    console.error("Error fetching assessment summary:", err.message);
                    setError(err.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [cAId, token]);

    if (loading) {
        return (
            <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
                <CircularProgress color="primary" />
            </Box>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const toggleView = () => {
        setShowClassTopicResults(!showClassTopicResults);
    };

    return (
        <Box sx={{ bgcolor: "#dce4e6", minHeight: "100vh", padding: 0 }}>
            <Container maxWidth={false} disableGutters>
                <Card
                    sx={{
                        mb: 2,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        boxShadow: "none",
                        borderBottom: "1px solid #ccc",
                    }}
                >
                    <Box display="flex" alignItems="center">
                        <IconButton onClick={() => navigate("/home")}>
                            <HomeIcon color="primary" />
                        </IconButton>
                        <IconButton onClick={() => navigate(-1)}>
                            <ChevronRightIcon color="primary" />
                        </IconButton>
                        <BreadcrumbNavigation
                            assessmentName={assessmentSummary?.assessment_name}
                            grade={assessmentSummary?.grade}
                            subject={assessmentSummary?.subject}
                            learnerName={
                                selectedLearner
                                    ? `${selectedLearner.learner.first_name} ${selectedLearner.learner.last_name}`
                                    : null
                            }
                            onBackToSummaryClick={() => setCurrentView("summary")}
                        />
                    </Box>
                    <Box display="flex" alignItems="center">
                        <Button
                            variant="outlined"
                            startIcon={<AutoAwesomeIcon />}
                            onClick={toggleView}
                            sx={{
                                backgroundColor: "#FFFFFF",
                                borderColor: "#5F9EA0",
                                color: "#5F9EA0",
                                borderRadius: "24px",
                                textTransform: "none",
                                padding: "6px 16px",
                                boxShadow: "none",
                                whiteSpace: "nowrap",
                                fontSize: "clamp(0.5rem, 1vw + 0.5rem, 1rem)",
                                minWidth: "100px",
                                mr: 1,
                                "&:hover": {
                                    backgroundColor: "#F0F0F0",
                                    borderColor: "#5F9EA0",
                                    boxShadow: "none",
                                },
                            }}
                        >
                            {showClassTopicResults ? "See Stats" : "Intervention and Analysis"}
                        </Button>
                    </Box>
                </Card>

                {/* Main Content Layout */}
                {showClassTopicResults ? (
                    <ClassTopicResults cAId={cAId} />
                ) : currentView === "learner" && selectedLearner ? (
                    <LearnerResultAnalysis
                        learnerData={selectedLearner}
                        grade={assessmentSummary?.grade}
                        subject={assessmentSummary?.subject}
                        assessment_name={assessmentSummary?.assessment_name}
                        totalLearners={assessmentSummary?.total_learners}
                        classAverage={assessmentSummary?.average_mark}
                        passMark={assessmentSummary?.pass_mark || 40}
                    />
                ) : (
                    <Grid container spacing={2} sx={{ margin: 0, width: "100%" }}>
                        <Grid item xs={12}>
                            <Box sx={{ mb: 2, width: "100%" }}>
                                <SummaryCards
                                    averageMark={assessmentSummary?.average_mark}
                                    totalLearners={assessmentSummary?.total_learners}
                                    totalSubmitted={assessmentSummary?.total_submitted}
                                    totalPassed={assessmentSummary?.passed_learners}
                                    totalFailedWithNonSubmit={assessmentSummary?.failed_learners}
                                    passedPercentage={assessmentSummary?.pass_percentage.toFixed(2)}
                                    failedPercentage={assessmentSummary?.fail_percentage.toFixed(2)}
                                />
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <ClassPerformanceStats ciAd={cAId} />
                            </Box>
                        </Grid>
                    </Grid>
                )}
            </Container>
        </Box>
    );
};

export default StatsPage;
